import React, { Component } from "react";
import {Row } from "react-bootstrap";
import "../../stylesheets/JoinUs.css";

export default class ThanksSection extends Component {
  render() {
    return (
      <div className="overall adjusting2">
        <div className="thanksdiv">
          <Row className="thanks">
            <img style={{ width: "10%" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Rectangle_10.png" alt=""/>
          </Row>
          <Row className="thanks">thank you for joining us</Row>
          </div>
          <div className="offerthanks py-5 mobileposonly"></div>
      </div>
    );
  }
}
