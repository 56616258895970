import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../stylesheets/ProjectsCarouselCSS.css";
import { Row, Container, Col } from "react-bootstrap";
export default class ProjectsCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      white: true,
    };
  }
  changeColor() {
    this.setState({ white: !this.state.white });
  }

  render() {
    const settings = {
      dots: true,
      dotsClass: "slick-dots",
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      more: false,
    };

    return (
      <div style={{ zIndex: "9999999" }}>
        <Slider {...settings}>
          {/* Stract section  */}

          <div className="slide1Item">
            <div className="slide6Img"></div>{" "}
            <picture>
              <source
                srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/carouselline.webp"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/webp"
              />
              <source
                srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/carouselline.png"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/png"
              />{" "}
              <img
                src="img/creakyOldJPEG.jpg"
                alt="slide1"
                className="img-fluid carouselline"
              />
            </picture>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "firstlineheader " +
                (this.state.white ? "caroutitleblue" : " caroutitlewhite")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Stract
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "secondlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              The business <br /> hub
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fourthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              bobian <br />
              for elevator <br /> technology
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fifthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              elesol <br /> australia
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sixthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              BRILLIANT <br /> LIFTS AUSTRALIA
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Locket <br /> digital wallet
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "eigthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              trust <br /> house
              <br />
              contracting
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "ninthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Dawody <br />
              For custom
              <br /> clearance
              <br /> and logistics
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "tenthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              pretty <br /> woman
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "elevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Krypton <br /> call center
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "twelvthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Fly <br /> Pyramids
            </p>
            <Container>
              <Row>
                <Col sm={12} className="offset-lg-1">
                  {" "}
                  <h2
                    className="myTitle"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      paddingTop: "35px",
                      textTransform: "uppercase",
                    }}
                  >
                    {/* projects that amaze */}
                  </h2>{" "}
                  <div className="slidecaption1">
                    {" "}
                    <h2>Stract </h2>
                    <h5>
                      UX/UI Design <br />
                      Mobile Application
                      <br />
                    </h5>
                    <Row>
                      <Col sm={12} md={9}>
                        <p>
                          STRACT is a Resource-Management Platform designed to
                          enable human resources agility in a way to enhance the
                          overall business agility.
                        </p>
                        <p>
                          STRACT platform is designed to allow organizations to
                          join and add their teams, create projects and tasks,
                          and contract with the available resources to overcome
                          peak needs during different project phases in a
                          dynamic stress-free method.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* The Business Hub section  */}

          <div className="slide1Item">
            <div className="slide4Img"></div>
            <picture>
              <source
                srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/carouselline.webp"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/webp"
              />
              <source
                srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/carouselline.png"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/png"
              />{" "}
              <img
                src="img/creakyOldJPEG.jpg"
                alt="slide1"
                className="img-fluid carouselline"
              />
            </picture>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "firstlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Stract
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "secondlineheader " +
                (this.state.white ? "caroutitleblue" : "caroutitlewhite")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              The business <br /> hub
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fourthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              bobian <br />
              for elevator <br /> technology
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fifthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              elesol <br /> australia
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sixthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              BRILLIANT <br /> LIFTS AUSTRALIA
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Locket <br /> digital wallet
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "eigthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              trust <br /> house
              <br />
              contracting
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "ninthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Dawody <br />
              For custom
              <br /> clearance
              <br /> and logistics
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "tenthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              pretty <br /> woman
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "elevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Krypton <br /> call center
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "twelvthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Fly <br /> Pyramids
            </p>
            <Container>
              <Row>
                <Col sm={12} className="offset-lg-1">
                  {" "}
                  <h2
                    className="myTitle"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      paddingTop: "35px",
                      textTransform: "uppercase",
                    }}
                  >
                    {/* projects that amaze */}
                  </h2>
                  <div className="slidecaption1">
                    {" "}
                    <h2>the business hub </h2>
                    <h5>
                      UX/UI Design <br /> web development
                    </h5>
                    <Row>
                      <Col sm={12} md={9}>
                        <p>
                          The Business Hub is a shared business offices. TBH
                          aims to provide work spaces with flixable rental plans
                          for starting business without worrying about any
                          overhead costs.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Popian section  */}

          <div className="slide1Item">
            <div className="slide2Img"></div>{" "}
            <picture>
              <source
                srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/carouselline.webp"
                alt="slide2"
                className="img-fluid carouselline"
                type="image/webp"
              />
              <source
                srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/carouselline.png"
                alt="slide2"
                className="img-fluid carouselline"
                type="image/png"
              />{" "}
              <img
                src="img/creakyOldJPEG.jpg"
                alt="slide2"
                className="img-fluid carouselline"
              />
            </picture>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "firstlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Stract
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "secondlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              The business <br /> hub
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fourthlineheader " +
                (this.state.white ? "caroutitleblue" : "caroutitlewhite")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              bobian <br />
              for elevator <br /> technology
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fifthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              elesol <br /> australia
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sixthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              BRILLIANT <br /> LIFTS AUSTRALIA
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Locket <br /> digital wallet
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "eigthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              trust <br /> house
              <br />
              contracting
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "ninthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Dawody <br />
              For custom
              <br /> clearance
              <br /> and logistics
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "tenthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              pretty <br /> woman
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "elevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Krypton <br /> call center
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "twelvthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Fly <br /> Pyramids
            </p>
            <Container>
              <Row>
                <Col sm={12} className="offset-lg-1">
                  {" "}
                  <h2
                    className="myTitle"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      paddingTop: "35px",
                      textTransform: "uppercase",
                    }}
                  >
                    {/* projects that amaze */}
                  </h2>
                  <div className="slidecaption1">
                    {" "}
                    <h2>Bobian for elevator </h2>
                    <h2> technology</h2>
                    <h5>
                      erp (Enterprise resource planning) <br /> web
                      design/development
                    </h5>
                    <Row>
                      <Col sm={12} md={9}>
                        <p>
                          Bobian for Elevators and Escalators Technology is a
                          fully owned Kuwaiti Company established in Kuwait in
                          2007. Primarily Bobian aims to supply all its
                          customers with the best of quality as well as pre and
                          post-sale services. They reached out because they are
                          going through a huge growth phase.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Elesol section  */}

          <div className="slide1Item">
            <div className="slide7Img"></div>{" "}
            <picture>
              <source
                srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/carouselline.webp"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/webp"
              />
              <source
                srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/carouselline.png"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/png"
              />{" "}
              <img
                src="img/creakyOldJPEG.jpg"
                alt="slide1"
                className="img-fluid carouselline"
              />
            </picture>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "firstlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Stract
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "secondlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              The business <br /> hub
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fourthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              bobian <br />
              for elevator <br /> technology
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fifthlineheader " +
                (this.state.white ? "caroutitleblue" : "caroutitlewhite")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              elesol <br /> australia
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sixthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              BRILLIANT <br /> LIFTS AUSTRALIA
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Locket <br /> digital wallet
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "eigthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              trust <br /> house
              <br />
              contracting
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "ninthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Dawody <br />
              For custom
              <br /> clearance
              <br /> and logistics
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "tenthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              pretty <br /> woman
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "elevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Krypton <br /> call center
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "twelvthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Fly <br /> Pyramids
            </p>
            <Container>
              <Row>
                <Col sm={12} className="offset-lg-1">
                  {" "}
                  <h2
                    className="myTitle"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      paddingTop: "35px",
                      textTransform: "uppercase",
                    }}
                  >
                    {/* projects that amaze */}
                  </h2>
                  <div className="slidecaption1">
                    {" "}
                    <h2>elesol australia </h2>
                    <h5>
                      CRM
                      <br />
                      web design/development
                    </h5>
                    <Row>
                      <Col sm={12} md={9}>
                        <p>
                          Elesol Australia is a fully owned Australian company
                          established in 2020. Elesol was a new sustainable
                          energy technology company that was entering a highly
                          competitive market selling electricity saving cards.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Brilliant section */}

          <div className="slide1Item">
            <div className="slide8Img"></div>{" "}
            <picture>
              <source
                srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/carouselline.webp"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/webp"
              />
              <source
                srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/carouselline.png"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/png"
              />{" "}
              <img
                src="img/creakyOldJPEG.jpg"
                alt="slide1"
                className="img-fluid carouselline"
              />
            </picture>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "firstlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Stract
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "secondlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              The business <br /> hub
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fourthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              bobian <br />
              for elevator <br /> technology
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fifthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              elesol <br /> australia
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sixthlineheader " +
                (this.state.white ? "caroutitleblue" : "caroutitlewhite")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              BRILLIANT <br /> LIFTS AUSTRALIA
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Locket <br /> digital wallet
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "eigthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              trust <br /> house
              <br />
              contracting
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "ninthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Dawody <br />
              For custom
              <br /> clearance
              <br /> and logistics
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "tenthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              pretty <br /> woman
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "elevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Krypton <br /> call center
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "twelvthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Fly <br /> Pyramids
            </p>
            <Container>
              <Row>
                <Col sm={12} className="offset-lg-1">
                  {" "}
                  <h2
                    className="myTitle"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      paddingTop: "35px",
                      textTransform: "uppercase",
                    }}
                  >
                    {/* projects that amaze */}
                  </h2>{" "}
                  <div className="slidecaption1">
                    {" "}
                    <h2>
                      BRILLIANT <br /> LIFTS AUSTRALIA
                    </h2>
                    <h5>web design/development</h5>
                    <Row>
                      <Col sm={12} md={9}>
                        <p>
                          Brilliant Lifts Australia (BLA) is a full service
                          elevator company who manufacture their own products in
                          a state of the art factory.
                        </p>
                        <p>
                          They know that their customers are the most important
                          part of the business and consistently strive to
                          improve further – never compromising for second best.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Locket section */}

          <div className="slide1Item">
            <div className="slide9Img"></div>{" "}
            <picture>
              <source
                srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/carouselline.webp"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/webp"
              />
              <source
                srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/carouselline.png"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/png"
              />{" "}
              <img
                src="img/creakyOldJPEG.jpg"
                alt="slide1"
                className="img-fluid carouselline"
              />
            </picture>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "firstlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Stract
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "secondlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              The business <br /> hub
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fourthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              bobian <br />
              for elevator <br /> technology
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fifthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              elesol <br /> australia
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sixthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              BRILLIANT <br /> LIFTS AUSTRALIA
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sevthlineheader " +
                (this.state.white ? "caroutitleblue" : " caroutitlewhite")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Locket <br /> digital wallet
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "eigthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              trust <br /> house
              <br />
              contracting
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "ninthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Dawody <br />
              For custom
              <br /> clearance
              <br /> and logistics
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "tenthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              pretty <br /> woman
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "elevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Krypton <br /> call center
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "twelvthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Fly <br /> Pyramids
            </p>
            <Container>
              <Row>
                <Col sm={12} className="offset-lg-1">
                  {" "}
                  <h2
                    className="myTitle"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      paddingTop: "35px",
                      textTransform: "uppercase",
                    }}
                  >
                    {/* projects that amaze */}
                  </h2>{" "}
                  <div className="slidecaption1">
                    {" "}
                    <h2>
                      {" "}
                      Locket <br /> digital wallet
                    </h2>
                    <h5>
                      UX/UI Design <br />
                      MOBILE APPLICATION DEVELOPMENT <br />
                      FUND MANAGEMENT SYSTEM
                      <br />
                    </h5>
                    <Row>
                      <Col sm={12} md={9}>
                        <p>
                          Locket is a virtual wallet that focuses on the newer
                          generation that challenges the status quo. The virtual
                          wallet assists younger generations to perform
                          purchases easily since many nowadays want to keep
                          their wallet bulk down.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Trust House section  */}

          <div className="slide1Item">
            <div className="slide10Img"></div>
            <picture>
              <source
                srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/carouselline.webp"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/webp"
              />
              <source
                srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/carouselline.png"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/png"
              />{" "}
              <img
                src="img/creakyOldJPEG.jpg"
                alt="slide1"
                className="img-fluid carouselline"
              />
            </picture>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "firstlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Stract
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "secondlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              The business <br /> hub
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fourthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              bobian <br />
              for elevator <br /> technology
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fifthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              elesol <br /> australia
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sixthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              BRILLIANT <br /> LIFTS AUSTRALIA
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Locket <br /> digital wallet
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "eigthlineheader " +
                (this.state.white ? "caroutitleblue" : " caroutitlewhite")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              trust <br /> house
              <br />
              contracting
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "ninthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Dawody <br />
              For custom
              <br /> clearance
              <br /> and logistics
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "tenthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              pretty <br /> woman
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "elevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Krypton <br /> call center
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "twelvthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Fly <br /> Pyramids
            </p>
            <Container>
              <Row>
                <Col sm={12} className="offset-lg-1">
                  {" "}
                  <h2
                    className="myTitle"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      paddingTop: "35px",
                      textTransform: "uppercase",
                    }}
                  >
                    {/* projects that amaze */}
                  </h2>
                  <div className="slidecaption1">
                    {" "}
                    <h2>
                      trust house
                      <br />
                      contracting{" "}
                    </h2>
                    <h5>
                      CRM
                      <br />
                      web design/development
                    </h5>
                    <Row>
                      <Col sm={12} md={9}>
                        <p>
                          Trust House is a contracting firm located in Kuwait
                          focusing on providing their clients with high quality
                          housing in an easy to find manner.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Dawody section  */}

          <div className="slide1Item">
            <div className="slide11Img"></div>{" "}
            <picture>
              <source
                srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/carouselline.webp"
                alt="slide2"
                className="img-fluid carouselline"
                type="image/webp"
              />
              <source
                srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/carouselline.png"
                alt="slide2"
                className="img-fluid carouselline"
                type="image/png"
              />{" "}
              <img
                src="img/creakyOldJPEG.jpg"
                alt="slide2"
                className="img-fluid carouselline"
              />
            </picture>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "firstlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Stract
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "secondlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              The business <br /> hub
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fourthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              bobian <br />
              for elevator <br /> technology
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fifthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              elesol <br /> australia
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sixthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              BRILLIANT <br /> LIFTS AUSTRALIA
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Locket <br /> digital wallet
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "eigthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              trust <br /> house
              <br />
              contracting
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "ninthlineheader " +
                (this.state.white ? "caroutitleblue" : " caroutitlewhite")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Dawody <br />
              For custom
              <br /> clearance
              <br /> and logistics
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "tenthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              pretty <br /> woman
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "elevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Krypton <br /> call center
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "twelvthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Fly <br /> Pyramids
            </p>
            <Container>
              <Row>
                <Col sm={12} className="offset-lg-1">
                  {" "}
                  <h2
                    className="myTitle"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      paddingTop: "35px",
                      textTransform: "uppercase",
                    }}
                  >
                    {/* projects that amaze */}
                  </h2>
                  <div className="slidecaption1">
                    {" "}
                    <h2>
                      dawody for custom clearance <br /> and logistics
                      technology
                    </h2>
                    <h5>web design/development</h5>
                    <Row>
                      <Col sm={12} md={9}>
                        <p>
                          Al Dawody is a custom clearance and logistics firm
                          located in Port Said and Mansoura, Egypt. They focus
                          on making import and export custom clearance an easy
                          task for their respective clients.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Pretty woman section  */}

          <div className="slide1Item">
            <div className="slide12Img"></div>{" "}
            <picture>
              <source
                srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/carouselline.webp"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/webp"
              />
              <source
                srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/carouselline.png"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/png"
              />{" "}
              <img
                src="img/creakyOldJPEG.jpg"
                alt="slide1"
                className="img-fluid carouselline"
              />
            </picture>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "firstlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Stract
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "secondlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              The business <br /> hub
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fourthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              bobian <br />
              for elevator <br /> technology
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fifthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              elesol <br /> australia
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sixthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              BRILLIANT <br /> LIFTS AUSTRALIA
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Locket <br /> digital wallet
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "eigthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              trust <br /> house
              <br />
              contracting
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "ninthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Dawody <br />
              For custom
              <br /> clearance
              <br /> and logistics
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "tenthlineheader " +
                (this.state.white ? "caroutitleblue" : " caroutitlewhite")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              pretty <br /> woman
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "elevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Krypton <br /> call center
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "twelvthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Fly <br /> Pyramids
            </p>
            <Container>
              <Row>
                <Col sm={12} className="offset-lg-1">
                  {" "}
                  <h2
                    className="myTitle"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      paddingTop: "35px",
                      textTransform: "uppercase",
                    }}
                  >
                    {/* projects that amaze */}
                  </h2>
                  <div className="slidecaption1">
                    {" "}
                    <h2>pretty woman </h2>
                    <h5>web design/development</h5>
                    <Row>
                      <Col sm={12} md={9}>
                        <p>
                          Pretty Woman is a beauty company located in Egypt
                          focusing on providing their clients with a wide
                          collection of skincare and makeup products.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Krypton section */}

          <div className="slide1Item">
            <div className="slide13Img"></div>{" "}
            <picture>
              <source
                srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/carouselline.webp"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/webp"
              />
              <source
                srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/carouselline.png"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/png"
              />{" "}
              <img
                src="img/creakyOldJPEG.jpg"
                alt="slide1"
                className="img-fluid carouselline"
              />
            </picture>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "firstlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Stract
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "secondlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              The business <br /> hub
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fourthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              bobian <br />
              for elevator <br /> technology
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fifthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              elesol <br /> australia
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sixthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              BRILLIANT <br /> LIFTS AUSTRALIA
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Locket <br /> digital wallet
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "eigthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              trust <br /> house
              <br />
              contracting
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "ninthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Dawody <br />
              For custom
              <br /> clearance
              <br /> and logistics
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "tenthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              pretty <br /> woman
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "elevthlineheader " +
                (this.state.white ? "caroutitleblue" : " caroutitlewhite")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Krypton <br /> call center
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "twelvthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Fly <br /> Pyramids
            </p>
            <Container>
              <Row>
                <Col sm={12} className="offset-lg-1">
                  {" "}
                  <h2
                    className="myTitle"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      paddingTop: "35px",
                      textTransform: "uppercase",
                    }}
                  >
                    {/* projects that amaze */}
                  </h2>{" "}
                  <div className="slidecaption1">
                    {" "}
                    <h2>
                      krypton <br /> call center
                    </h2>
                    <h5>web design/development</h5>
                    <Row>
                      <Col sm={12} md={9}>
                        <p>
                          Krypton system incorporates and encodes the customer
                          base and direct linking between customer complaints
                          and requirements and the specialised team.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Fly pyramids section */}

          <div className="slide1Item">
            <div className="slide14Img"></div>{" "}
            <picture>
              <source
                srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/carouselline.webp"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/webp"
              />
              <source
                srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/carouselline.png"
                alt="slide1"
                className="img-fluid carouselline"
                type="image/png"
              />{" "}
              <img
                src="img/creakyOldJPEG.jpg"
                alt="slide1"
                className="img-fluid carouselline"
              />
            </picture>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "firstlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Stract
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "secondlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              The business <br /> hub
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fourthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              bobian <br />
              for elevator <br /> technology
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "fifthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              elesol <br /> australia
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sixthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              BRILLIANT <br /> LIFTS AUSTRALIA
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "sevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Locket <br /> digital wallet
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "eigthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              trust <br /> house
              <br />
              contracting
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "ninthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Dawody <br />
              For custom
              <br /> clearance
              <br /> and logistics
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "tenthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              pretty <br /> woman
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "elevthlineheader " +
                (this.state.white ? "caroutitlewhite" : "caroutitleblue")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Krypton <br /> call center
            </p>
            <p
              onClick={this.changeColor.bind(this)}
              className={
                "twelvthlineheader " +
                (this.state.white ? "caroutitleblue" : " caroutitlewhite")
              }
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "11.5px",
              }}
            >
              Fly <br /> Pyramids
            </p>
            <Container>
              <Row>
                <Col sm={12} className="offset-lg-1">
                  {" "}
                  <h2
                    className="myTitle"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      paddingTop: "35px",
                      textTransform: "uppercase",
                    }}
                  >
                    {/* projects that amaze */}
                  </h2>{" "}
                  <div className="slidecaption1">
                    {" "}
                    <h2>fly pyramids</h2>
                    <h5>web design/development</h5>
                    <Row>
                      <Col sm={12} md={9}>
                        <p>
                          Fly Pyramids is an Egyptian company that wanted to
                          create a website to display the company's services in
                          the field of aviation services, such as renting
                          private aircraft and transporting goods.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Slider>
      </div>
    );
  }
}
