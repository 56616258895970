import React, { Component } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { emailLink } from "../../config.js";
import axios from "axios";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submail: "",
      show: false,
    };
  }

  handleSubscribe = async (e) => {
    e.preventDefault();
    const eventdata = new FormData();
    eventdata.append("receiverEmail[0]", this.state.submail);
    eventdata.append("subject", "Lirten Solutions-Portfolio");
    eventdata.append(
      "body",
      `Thank you for considering our services. \n\nKindly find attached below a copy of our latest portfolio. \n\nIn case you have any questions or would like to discuss your future project, you can give us a call on 01021714995 or email us on info@lirten.com. \n\nBest regards, \nLirten Solutions`
    );

    eventdata.append("password", "mpN'y5{jN,9q{H4:");
    var file = "";
    const response = await axios
      .get(
        "https://lirtenhub.s3.eu-central-1.amazonaws.com/Lirten+Portfolio+-2023.pdf",
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        const filename = "Lirten-Portfolio.pdf";
        file = new File([response.data], filename, {
          type: "application/pdf",
        });
        eventdata.append("file1", file);
      })
      .catch((error) => {
        console.error(error);
      });
    this.setState({
      show: true,
    });
    setTimeout(() => {
      this.setState({ show: false });
    }, 1300);

    if (this.state.submail === "") {
      this.setState({ show2: true });
    } else {
      const res = await axios({
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: eventdata,
        url: emailLink,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  };
  handlesubmail = (e) => {
    this.setState({ submail: e.target.value });
  };
  render() {
    return (
      <footer className="pb-3 px-5">
        <Container>
          <Row>
            <Col md={12} lg={3} className="p-0 mt-5">
              <div className="footerlogo ">
                <picture>
                  <source
                    srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/footerlogo.webp"
                    alt="logo"
                    type="image/webp"
                  />
                  <source
                    srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/footerlogo.png"
                    alt="logo"
                    type="image/png"
                  />{" "}
                  <img src="img/creakyOldJPEG.jpg" alt="logo" />
                </picture>
              </div>
              {/* <p className=" footer-rights py-5">
                lirten all rights reserved ©2019
              </p> */}
            </Col>
            <Col md={12} lg={7} className="offset-lg-1 p-0">
              <Form>
                {" "}
                <Col sm={12} className="offset-lg-1 px-0">
                  <div className="info mb-5 mt-5">
                    <p>CONTACT INFO</p>
                    <span>
                      {" "}
                      <span style={{ color: "#D0AD5E", fontWeight: "100" }}>
                        {" "}
                        E:{" "}
                      </span>{" "}
                      info@lirten.com
                    </span>{" "}
                    <br />
                    {/* <span>
                      <span style={{ color: '#D0AD5E', fontWeight: '100' }}>
                        {' '}
                        P:{' '}
                      </span>{' '}
                      +2 01003388470
                    </span> */}
                  </div>{" "}
                  <div className=" footerform">
                    <h3 className="news">Request For Portfolio</h3>

                    <Form.Group controlId="formGroupEmail">
                      <Form.Control
                        noValidate
                        required
                        type="email"
                        value={this.state.submail}
                        onChange={this.handlesubmail}
                        name="email2"
                        placeholder="E-Mail"
                        className="contactForm mt-4"
                      />
                    </Form.Group>
                  </div>
                </Col>{" "}
                <Col sm={12} className="offset-lg-1">
                  <div className="formbuttons">
                    <Row>
                      <Col md={3} className="pl-0">
                        <Button
                          type="submit "
                          className="px-4 mx-0"
                          onClick={this.handleSubscribe}
                        >
                          SUBSCRIBE
                        </Button>
                      </Col>
                      <Col md={4} sm={12} className="pl-0">
                        <p>
                          TYPE IN YOUR EMAIL AND
                          <br /> SUBSCRIBE TO REQUEST
                          <br /> FOR PORTFOLIO
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>{" "}
              </Form>
            </Col>
            <Col sm={7}>
              {" "}
              <p className=" footer-rights mt-5">
                lirten all rights reserved © {new Date().getFullYear()}
              </p>
            </Col>
          </Row>
          <Modal className="mt-2" show={this.state.show}>
            <div id="snackbar">Sent Successfully</div>
          </Modal>
          <Row>
            {/* <Col sm={6}>   <p className=" footer-rights py-5">
                lirten all rights reserved ©2019
              </p></Col> */}
            <Col sm={12}>
              <div className="icons">
                <a
                  href="https://www.linkedin.com/in/lirten-solutions-86471b19a"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin pr-2"></i>
                </a>
                <a
                  href="https://twitter.com/LirtenSolutions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab  fa-twitter-square"></i>
                </a>
                <a
                  href="https://www.facebook.com/LirtenSolutions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <i className="fab  fa-facebook-square"></i>
                </a>
                {/* here will be instagram link */}
                <a
                  href="https://www.instagram.com/lirten_solutions/?igshid=MzNlNGNkZWQ4Mg%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </Col>
          </Row>
        </Container>

        <picture>
          <source
            srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/Vector10.webp"
            className="vector"
            alt="vector"
            type="image/webp"
          />
          <source
            srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/myVector1.png"
            className="vector"
            alt="vector"
            type="image/png"
          />{" "}
          <img src="img/creakyOldJPEG.jpg" className="vector" alt="vector" />
        </picture>

        <picture>
          <source
            srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/Vector9.webp"
            className="vector2"
            alt="vector2"
            type="image/webp"
          />
          <source
            srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/myVector2.png"
            className="vector2"
            alt="vector2"
            type="image/png"
          />{" "}
          <img src="img/creakyOldJPEG.jpg" className="vector2" alt="vector2" />
        </picture>
      </footer>
    );
  }
}
