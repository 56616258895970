import React, { Component } from "react";
import { Container, Row, Col, Card, ListGroup, Button } from "react-bootstrap";
import Moment from "react-moment";
import "../../stylesheets/JoinUs.css";
import InternCarousel from "../joinUs-sections/InternCarousel";
// const {vacancies} = require ('../../../data/vacancies')

export default class Interncard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleapply = this.handleapply.bind(this);
  }

  handleapply = (e) => {
    e.preventDefault();
    this.props.handleapply();
  };

  render() {
    const outcome = (
      // this.props.list.outcome.map((ell) => (
      //   <Row className="positioningmobile">
      //     <div>
      //       {" "}
      //       <img style={{ width: "9px" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector_2.png" alt=" " />{" "}
      //     </div>
      //     <Col className="p-0 ml-1">
      //       <ul className="listing">{ell}</ul>{" "}
      //     </Col>
      //   </Row>
      // ));
      <Row className="positioningmobile">
        <Col sm={12} md={6}>
          <Row>
            <div>
              {" "}
              <img
                style={{ width: "10px" }}
                src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector.png"
                alt=" "
              />{" "}
            </div>
            <ul className="listing pl-2">
              {this.props.list.jobTitle === "Graphic Designer"
                ? "UI/UX Design"
                : "Software Engineering"}
            </ul>{" "}
          </Row>
          <Row>
            <div>
              {" "}
              <img
                style={{ width: "9px" }}
                src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector_2.png"
                alt=" "
              />{" "}
            </div>
            <ul className="listing pl-2">
              {this.props.list.jobTitle === "Graphic Designer"
                ? "Workflow"
                : "Software Architecture"}
            </ul>{" "}
          </Row>
        </Col>
        <Col sm={12} md={6}>
          <Row>
            <div>
              {" "}
              <img
                style={{ width: "10px" }}
                src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector.png"
                alt=" "
              />{" "}
            </div>
            <ul className="listing pl-2">
              {this.props.list.jobTitle === "Graphic Designer"
                ? "Figma Software"
                : "Administration System"}
            </ul>{" "}
          </Row>
          <Row>
            <div>
              {" "}
              <img
                style={{ width: "9px" }}
                src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector_2.png"
                alt=" "
              />{" "}
            </div>
            <ul className="listing pl-2">
              {this.props.list.jobTitle === "Graphic Designer"
                ? "Visual Design"
                : "MERN-Stack"}
            </ul>{" "}
          </Row>
        </Col>
      </Row>
    );

    // const tech = this.props.list.requirements.map((ell) => (
    //   <Row className="positioningmobile">
    //     <div>
    //       {" "}
    //       <img style={{ width: "9px" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector.png" alt=" " />{" "}
    //     </div>
    //     <Col className="p-0 ml-1">
    //       <ul className="listing">{ell}</ul>{" "}
    //     </Col>
    //   </Row>
    // ));

    // const personal = this.props.list.requirementsPersonal.map((ell) => (
    //   <Row className="positioningmobile">
    //     <div>
    //       {" "}
    //       <img style={{ width: "9px" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector_2.png" alt=" " />{" "}
    //     </div>
    //     <Col className="p-0 ml-1">
    //       <ul className="listing">{ell}</ul>{" "}
    //     </Col>
    //   </Row>
    // ));
    // const linkbody =
    //   this.state.linkText == "more info" ? (
    //     <>
    //       <div className="mr-2 pt-1">{this.state.linkText}</div>

    //       <div className="pt-2 pr-1">
    //         {" "}
    //         <img style={{ width: "7px" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector.png" alt=" " />{" "}
    //       </div>
    //       <div className="pt-1 pr-1">
    //         {" "}
    //         <img style={{ width: "22px" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Rectangle_8.png" alt=" " />{" "}
    //       </div>
    //       <div>
    //         {" "}
    //         <img style={{ width: "7px" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector.png" alt=" " />{" "}
    //       </div>
    //     </>
    //   ) : (
    //       <>
    //         <div className="mr-2 pt-1 mt-3 ml-5">{this.state.linkText}</div>

    //         <div>
    //           <div>
    //             <img style={{ width: "7px" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector_1.png" alt=" " />{" "}
    //           </div>
    //           <div className="ml-1 ">
    //             {" "}
    //             <img style={{ height: "23px" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Rectangle_h.png" alt=" " />{" "}
    //           </div>
    //           <div className="ml-1">
    //             {" "}
    //             <img style={{ width: "7px" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector_1.png" alt=" " />{" "}
    //           </div>
    //         </div>
    //       </>
    //     );

    const cardbody = (
      //   this.state.linkText == "more info" ? (
      //     <div onClick={this.handleClickXl}>
      //       <Row >
      //         <Col sm={12} md={8}>
      //           <Row>
      //             <div className="jobTitle">
      //               <div className="mr-1">
      //                 {" "}
      //                 <img style={{ width: "9px" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector.png" alt=" " />{" "}
      //               </div>
      //               {this.props.list.jobTitle.toLowerCase()} &nbsp;
      //             </div>
      //           </Row>
      //         </Col>
      //         <Col sm={12} md={4} className="postedOn leftshift3">
      //           <div>
      //             {" "}
      //             <svg
      //               class="bi bi-clock"
      //               width="1em"
      //               height="1em"
      //               viewBox="0 0 16 16"
      //               fill="currentColor"
      //               xmlns="http://www.w3.org/2000/svg"
      //             >
      //               <path
      //                 fill-rule="evenodd"
      //                 d="M8 15A7 7 0 108 1a7 7 0 000 14zm8-7A8 8 0 110 8a8 8 0 0116 0z"
      //                 clip-rule="evenodd"
      //               />
      //               <path
      //                 fill-rule="evenodd"
      //                 d="M7.5 3a.5.5 0 01.5.5v5.21l3.248 1.856a.5.5 0 01-.496.868l-3.5-2A.5.5 0 017 9V3.5a.5.5 0 01.5-.5z"
      //                 clip-rule="evenodd"
      //               />
      //             </svg>
      //             &nbsp;
      //             <Moment fromNow parse="DD-MM-YYYY HH:mm">{this.props.list.date}</Moment>
      //           </div>
      //         </Col>
      //       </Row>
      //       <div className="cardTextBody">
      //         <Row className="positioning">
      //           <Col sm={12} md={8} lg={9}>
      //             <Row className="positioning2">
      //               <div>{this.props.list.about}</div>
      //             </Row>
      //             <Row>
      //               {this.props.list.careerLevel &&
      //                 this.props.list.careerLevel.match(/(.*[a-z]){1}/i) ? (
      //                   <Col sm={12} md={5}>
      //                     <Row className="positioning2">
      //                       <p className="mobile2"> career level:&nbsp; </p>
      //                       <div>
      //                         {" "}
      //                         {this.props.list.careerLevel} &nbsp;
      //                     </div>
      //                     </Row>
      //                   </Col>
      //                 ) : null}
      //               {this.props.list.experience &&
      //                 this.props.list.experience.match(/(.*[a-z]){1}/i) ? (
      //                   <Col sm={12} md={5}>
      //                     <Row className="positioning2">
      //                       <p className="mobile2"> experience needed:&nbsp; </p>
      //                       <div>
      //                         {" "}
      //                         {this.props.list.experience} &nbsp;{" "}
      //                       </div>
      //                     </Row>
      //                   </Col>
      //                 ) : null}
      //             </Row>

      //             <Row>
      //               {this.props.list.deadline ? (
      //                 <Col sm={12} md={5}>
      //                   <Row className="positioning2">
      //                     <p className="mobile2">deadline: &nbsp; </p>
      //                     {/* <Moment format="MMM Do YYYY"  parse="DD-MM-YYYY" className="deadline"> */}
      //                     {this.props.list.deadline}
      //                     {/* </Moment> */}
      //                   </Row>
      //                 </Col>
      //               ) : null}
      //               {this.props.list.jobType &&
      //                 this.props.list.jobType.match(/(.*[a-z]){1}/i) ? (
      //                   <Col sm={12} md={7}>
      //                     <Row className="positioning2">
      //                       <p className="mobile2">job type: &nbsp; </p>
      //                       <div>
      //                         {this.props.list.jobType} &nbsp;
      //                     </div>
      //                     </Row>
      //                   </Col>
      //                 ) : null}
      //             </Row>
      //             <Row></Row>
      //           </Col>
      //         </Row>
      //         <Row>
      //           <Col sm={12} md={9} lg={10}>
      //             &nbsp;
      //           </Col>
      //           <Col sm={12} md={3} lg={2}></Col>
      //         </Row>
      //       </div>
      //     </div>
      //   ) : (
      <>
        <Row>
          <Col sm={12} md={8}>
            <Row>
              <div className="jobTitle">
                {this.props.list.jobTitle.toLowerCase()}
                <div className="mobilepos">&nbsp;</div>
              </div>
            </Row>
          </Col>
          <Col sm={12} md={4} className="postedOn leftshift3">
            <div>
              {" "}
              <svg
                className="bi bi-clock"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 15A7 7 0 108 1a7 7 0 000 14zm8-7A8 8 0 110 8a8 8 0 0116 0z"
                  clip-rule="evenodd"
                />
                <path
                  fill-rule="evenodd"
                  d="M7.5 3a.5.5 0 01.5.5v5.21l3.248 1.856a.5.5 0 01-.496.868l-3.5-2A.5.5 0 017 9V3.5a.5.5 0 01.5-.5z"
                  clip-rule="evenodd"
                />
              </svg>
              &nbsp;
              <Moment fromNow parse="DD-MM-YYYY HH:mm">
                {this.props.list.date}
              </Moment>
            </div>
          </Col>
        </Row>
        <div className="cardTextBody">
          <ListGroup>
            {this.props.list.desc &&
            this.props.list.desc.match(/(.*[a-z]){1}/i) ? (
              <ListGroup.Item>
                <Row className="positioning22  positioning_bottom2">
                  <Col sm={12} md={2} className="mobile mr-4">
                    description:
                  </Col>
                  <Col sm={12} md={9}>
                    <Row className="positioningmobile">
                      <div>
                        {" "}
                        <img
                          style={{ width: "9px" }}
                          src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector_2.png"
                          alt=" "
                        />{" "}
                      </div>
                      <Col className="p-0 ml-1">
                        <p className="description-paragraph">
                          {" "}
                          {this.props.list.desc}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ListGroup.Item>
            ) : null}
            <ListGroup.Item>
              <Row className="duration-row">
                <Col sm={12} md={5}>
                  {this.props.list.duration &&
                  this.props.list.duration.match(/(.*[a-z]){1}/i) ? (
                    <Row>
                      <Col sm={12} md={6} className="mobile">
                        minimum duration:
                      </Col>
                      <Col sm={12} md={6}>
                        <Row className="positioningmobile">
                          <div className="mr-1">
                            {" "}
                            <img
                              style={{ width: "9px" }}
                              src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector.png"
                              alt=" "
                            />{" "}
                          </div>
                          {this.props.list.duration}
                        </Row>
                      </Col>
                    </Row>
                  ) : null}
                  {/* {this.props.list.deadline ? (
                        <Row className="positioning22  positioning_bottom2">
                          <Col sm={12} md={6} className="mobile">
                            deadline:
                        </Col>
                          <Col sm={12} md={6}>
                            <Row className="positioningmobile">
                              <div className="mr-1">
                                {" "}
                                <img style={{ width: "9px" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector_2.png" alt=" " />{" "}
                              </div>
                              <Moment format="MMM Do YYYY" parse="DD-MM-YYYY" className="deadline">
                              {this.props.list.deadline}
                              </Moment>
                              &nbsp;
                            </Row>
                          </Col>
                        </Row>
                      ) : null}
                      {this.props.list.joinDate ? (
                        <Row className="positioning22  positioning_bottom2">
                          <Col sm={12} md={6} className="mobile">
                            expected join date:
                        </Col>
                          <Col sm={12} md={6}>
                            <Row className="positioningmobile">
                              <div className="mr-1">
                                {" "}
                                <img style={{ width: "9px" }} src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector.png" alt=" " />{" "}
                              </div>
                              <Moment format="MMMM YYYY"  parse="MM-YYYY"> 
                              {this.props.list.joinDate}
                              </Moment>
                            </Row>
                          </Col>
                        </Row>
                      ) : null} */}
                </Col>
                {/* <Col
                  md={1}
                  className='positioning22 p-0 mobilepos duration-vectors'
                >
                  <div>
                    <div>
                      <img
                        style={{ width: '12px' }}
                        src='https://lirten.s3.eu-central-1.amazonaws.com/website/Vector_1.png'
                        alt=' '
                      />{' '}
                    </div>
                    <div className='ml-2 mt-2'>
                      {' '}
                      <img
                        style={{ height: '38px' }}
                        src='https://lirten.s3.eu-central-1.amazonaws.com/website/Rectangle_h.png'
                        alt=' '
                      />{' '}
                    </div>
                    <div className='ml-2 mt-2'>
                      {' '}
                      <img
                        style={{ width: '12px' }}
                        src='https://lirten.s3.eu-central-1.amazonaws.com/website/Vector_1.png'
                        alt=' '
                      />{' '}
                    </div>
                  </div>
                </Col> */}
                <Col sm={12} md={6}>
                  {this.props.list.Round1 ? (
                    <Row className="positioning2 duration-round">
                      <Col sm={12} md={6} className="mobile">
                        round
                        <div style={{ fontSize: "14px" }}>&nbsp; 1:</div>
                      </Col>
                      <Col sm={12} md={6}>
                        <Row className="positioningmobile">
                          <div className="mr-1">
                            {" "}
                            <img
                              style={{ width: "10px" }}
                              src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector.png"
                              alt=" "
                            />{" "}
                          </div>
                          {this.props.list.Round1}
                        </Row>
                      </Col>
                    </Row>
                  ) : null}
                  {this.props.list.Round2 ? (
                    <Row className="positioning22  positioning_bottom2 duration-round">
                      <Col sm={12} md={6} className="mobile">
                        round
                        <div style={{ fontSize: "14px" }}>&nbsp; 2:</div>
                      </Col>
                      <Col sm={12} md={6}>
                        <Row className="positioningmobile">
                          <div className="mr-1">
                            {" "}
                            <img
                              style={{ width: "9px" }}
                              src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector_2.png"
                              alt=" "
                            />{" "}
                          </div>

                          {this.props.list.Round2}
                        </Row>
                      </Col>
                    </Row>
                  ) : null}
                  {this.props.list.Round3 ? (
                    <Row className="positioning22 mb-1 duration-round">
                      <Col sm={12} md={6} className="mobile">
                        round
                        <div style={{ fontSize: "14px" }}>&nbsp; 3:</div>
                      </Col>
                      <Col sm={12} md={6}>
                        <Row className="positioningmobile">
                          <div>
                            {" "}
                            <img
                              style={{ width: "10px" }}
                              src="https://lirten.s3.eu-central-1.amazonaws.com/website/Vector.png"
                              alt=" "
                            />{" "}
                          </div>
                          <Col className="p-0 ml-1">
                            <ul className="listing">
                              {this.props.list.Round3}
                            </ul>{" "}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item onClick={this.handleClickMd}>
              {this.props.list.outcome && this.props.list.outcome !== [] ? (
                <Row className="positioning22  positioning_bottom2">
                  <Col sm={12} md={2} className="mobile mr-4">
                    experience outcome:
                  </Col>
                  <Col sm={12} md={9}>
                    {outcome}
                  </Col>
                </Row>
              ) : null}
              {/* </ListGroup.Item> */}
              {/* <ListGroup.Item onClick={this.handleClickLg}>
                  {this.props.list.requirements &&
                    this.props.list.requirements !== [] ? (
                      <Row className="positioning22  positioning_bottom2">
                        <Col sm={12} md={2} className="mobile mr-4">
                          job requirements:
                    </Col>
                         <Col sm={12} md={9}> 
                          {tech}
                        </Col>
                       </Row>
                    ) : null} */}

              {/* {this.props.list.requirementsPersonal &&
                    this.props.list.requirementsPersonal !== [] ? (
                      <Row className="positioning22  positioning_bottom2">
                        <Col sm={12} md={2} className="mobile mr-4">
                          personal skills:
                    </Col>
                        <Col sm={12} md={9}>
                          {personal}
                        </Col>
                      </Row>
                    ) : null} */}
            </ListGroup.Item>
            <ListGroup.Item>
              <table>
                <tr>
                  <td className="titlewidth mobile mt-4 m-0 p-0">
                    program outline:
                  </td>
                  <td className="subwidth">
                    <InternCarousel jobTitle={this.props.list.jobTitle} />
                  </td>
                </tr>
              </table>
            </ListGroup.Item>
            {/* <ListGroup.Item>
              <Row className='positioning22  positioning_bottom2'>
                <Col sm={12} md={2} className='mobile mr-4'>
                  Application Deadlines:
                </Col>
                <Col sm={12} md={9}>
                  <Row className='positioningmobile'>
                    <div>
                      {' '}
                      <img
                        style={{ width: '9px' }}
                        src='https://lirten.s3.eu-central-1.amazonaws.com/website/Vector_2.png'
                        alt=' '
                      />{' '}
                    </div>
                    <Col className='p-0 ml-1'> 11/8/2021 </Col>
                  </Row>
                </Col>
              </Row>
            </ListGroup.Item> */}
          </ListGroup>
        </div>
      </>
    );

    // );
    return (
      <div>
        <Container>
          <Row>
            <Card
              className="shadow joinuscard mt-3"
              // onClick={this.handleClick}
            >
              <Card.Body>
                {cardbody}
                <Row>
                  <Col className="leftshift">
                    <Button
                      className="applyButton"
                      onClick={this.handleapply}
                      title={this.props.list.jobTitle}
                    >
                      Apply Now
                    </Button>
                  </Col>
                  {/* <Col className="leftshift2">
                    <Link onClick={this.handleClickXl} className="linkText">
                      {linkbody}
                    </Link> */}
                  {/* </Col> */}
                </Row>
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </div>
    );
  }
}
