// {
//   jobTitle: '',
//   careerLevel: '',
//   experience: '',
//   about:
//     '',
//   joinDate: '',
//   jobDesc: [
//    ''
//   ],
//   degree: '',
//   date: '',
//   deadline: '',
//   jobType: '',

//   requirements: [
//     ''
//   ],
//   id:
// },

const internship = [];

module.exports = { internship };
