const vacancies = [
  // {
  //   jobTitle: "Business Developer",
  //   careerLevel: "Graduate",
  //   experience: "1-2 Years",
  //   jobDesc: [
  //     "Preparing a business plan to open new markets and identifying target customers to sell the company's services within 3 months.",
  //     "Develop new business and long-term opportunities through prospecting.",
  //     "Develop, coordinate and implement sales plans designed to maintain and increase existing business and capture new opportunities.",
  //     "Develop sales presentations and participate in meetings with clients.",
  //     "Complete proposals, follow up on submissions and close business deals.",
  //     "Oversee, maintain and upgrade existing accounts to ensure ongoing client satisfaction.",
  //     "Negotiating and closing deals.",
  //     "Following up with the clients for revenue collection.",
  //     "Researching active customers in the market.",
  //     "Analyze existing services and markets in terms of the ability to compete, gain market share, grow revenue and sustain or grow profitability.",
  //     "Collaborate with Sales, Marketing to define effective sales channels of all products.",
  //     "Establish an effective relationship with the sales force with leadership, training, pricing and positioning expertise.",
  //     "Responsible (in collaboration with sales) for the development of the company’s revenue forecast.",
  //     "Experience with inbound and outbound marketing, design and implement marketing initiatives including newsletter, trade-shows, webinars, collateral, social media.",
  //   ],
  //   degree: "Bachelor's degree in Business or any related major.",
  //   date: "1/1/2023",
  //   deadline: "10/12/2022",
  //   jobType: "Full Time",
  //   requirements: [
  //     "Works well autonomously and as part of a team.",
  //     "Excellent organizational skills and attention to detail and takes pride in their work.",
  //     "Excellent time management skills with a proven ability to meet deadlines.",
  //     "Research skills and analytical thinking.",
  //     "Innovation and problem-solving skills that include the ability to develop and propose solutions for clients.",
  //     "Strong negotiation and decision-making skills.",
  //     "Excellent listening and presentation abilities.",
  //     "Ability to prioritize tasks.",
  //     "Ability to manage multiple projects and responsibilities simultaneously.",
  //     "Ability to function well in a high-paced and at times stressful environment.",
  //     "A strong communicator with excellent writing skills.",
  //     "Self-motivated, proactive, enthusiastic and eager to learn.",
  //     "Determined and flexible with an upbeat positive approach.",
  //   ],
  //   id: 1,
  // },
  // {
  //   jobTitle: "Junior Graphic Designer",
  //   careerLevel: "Graduate",
  //   experience: "1 year",
  //   jobDesc: [
  //     "Fast thinker, Ability to create high-quality content from a loose or constrained brief with a short deadline.",
  //     "Design images, icons, banners, and printable material.",
  //     "Use the appropriate colors and layouts for each graphic based on the brand.",
  //     "Regularly collaborate with the marketing team.",
  //     "Thinking creatively to produce new ideas and concepts.",
  //     "Create designs, concepts, and sample layouts for social media accounts based on knowledge of layout principles and visual design concepts.",
  //     "Understand and stay up to date on the ever-evolving social platform creative units/specs for pages and ads.",
  //     "Your graphics should capture the attention of those who see them communicate the right message.",
  //   ],
  //   degree: "Bachelor’s degree in Graphic Design or any related major.",
  //   date: "1/1/2023",
  //   deadline: "10/12/2022",
  //   jobType: "Full Time",
  //   requirements: [
  //     "Must have experience 1 year in photoshop, UI/UX, illustrator, InDesign, figma & adobe XD.",
  //     "Experience in premiere and aftereffects is preferred.",
  //     "Bachelor’s degree in graphic design or related field.",
  //     "Creative thinker – thinks outside the box.",
  //     "Effective time management skills and the ability to meet deadlines.",
  //     "Great interpersonal and communication skills.",
  //     "Graphic design skills with a strong portfolio.",
  //     "Ability to multi-task and adapt easily to change.",
  //     "Understanding of marketing, corporate identity, advertisements, and multimedia designs.",
  //     "Excellent eye for detail.",
  //     "Organized.",
  //     "You need to have your own laptop.",
  //   ],
  //   id: 2,
  // },
  // {
  //   jobTitle: "HR Generalist",
  //   careerLevel: "Graduate",
  //   experience: "1-2 Years",
  //   jobDesc: [
  //     "Management skills.",
  //     "Preparing the documentary  cycle for employees and the evaluation system.",
  //     "Follow up the financial system of employees, including salaries, vacations, and government transactions.",
  //     "Administer compensation and benefit plans.",
  //     "Provide support to employees in various HR-related topics such as leaves and compensation and resolve any issues that may arise.",
  //     "Promote HR programs to create an efficient and conflict-free workplace.",
  //     "Assist in development and implementation of human resource policies.",
  //     "Undertake tasks around performance management.",
  //     "Organize quarterly and annual employee performance reviews.",
  //     "Handling office tasks, such as filing, generating reports and presentations, setting up for meetings, and reordering supplies.",
  //   ],
  //   date: "1/1/2023",
  //   deadline: "10/12/2022",
  //   jobType: "Full Time",
  //   requirements: [
  //     "Proven work experience as a HR Generalist or similar role not less than 1 years of experience.",
  //     "Hands-on experience with full-cycle recruiting using various interview techniques and evaluation methods.",
  //     "Excellent verbal and written communication skills.",
  //     "A keen understanding of the differences between various roles within organizations.",
  //     "BS in Human Resources Management or relevant field.",
  //     "Excellent in English.",
  //     "Knowledge of payroll.",
  //     "Basic understanding of accounting.",
  //   ],
  //   id: 3,
  // },
  // {
  //   jobTitle: "Project Manager",
  //   careerLevel: "Graduate",
  //   experience: "2-3 Years",
  //   jobDesc: [
  //     "Developing System High-Level Requirements.",
  //     "Keeping the Documentation.",
  //     "Briefing supervisors.",
  //     "Enhance existing software, correct errors and develop new ones.",
  //     "Preparing presentations.",
  //     "Preparing test cases for the underdeveloped system.",
  //     "Performing User Acceptance Testing.",
  //     "Allocated Resources.",
  //     "Managing the workflow.",
  //     "Briefing designers.",
  //     "Branding Acceptance from designers.",
  //   ],
  //   degree:
  //     "Bachelor’s degree in Information Technology, Software Development Management, Software Engineering, BI or any related major.",
  //   date: "1/1/2023",
  //   deadline: "10/12/2022",
  //   jobType: "Full Time",
  //   requirements: [
  //     "Proven experience in project management and software development.",
  //     "Excellent technical knowledge.",
  //     "Good leadership, decision-making, and organization skills.",
  //     "Strong attention to detail and multi-tasking skills.",
  //     "Defining the project scope.",
  //     "Conducting business research.",
  //     "Gathering project requirements.",
  //     "Creating requirements specifications.",
  //     "Translating requirements to the team.",
  //     "Performing acceptance testing.",
  //   ],
  //   id: 4,
  // },
  // {
  //   jobTitle: "Quality Control Engineer",
  //   careerLevel: "Graduate",
  //   experience: "1-3 Years",
  //   jobDesc: [
  //     "Creating, reviewing, and maintaining manual test scripts.",
  //     "Report & track defects.",
  //     "Manage test data and environments needed.",
  //     "Creating, reviewing, and maintaining automated test scripts.",
  //     "Works within the team using agile development methodologies.",
  //   ],
  //   degree: "Bachelor’s degree in Computer Science or any related major.",
  //   date: "1/1/2023",
  //   deadline: "10/12/2022",
  //   jobType: "Full Time",
  //   requirements: [
  //     "Excellent communication skills.",
  //     "Knowledge of software testing principles, testing processes, and methodologies.",
  //     "Ability to work under pressure and to tight deadlines.",
  //     "Possession of excellent analytical and troubleshooting skills.",
  //     "Experience working in an Agile/Scrum development process.",
  //     "Experience in one of the following testing fields is a must (Automation, Performance - load, Security).",
  //   ],
  //   id: 5,
  // },
  // {
  //   jobTitle: "Senior Back End Developer",
  //   careerLevel: "Graduate",
  //   experience: "2-3 Years",
  //   jobDesc: [
  //     "Develop new microservices.",
  //     "Build reusable code and libraries for future use.",
  //     "Ensure the technical feasibility of user requirements.",
  //     "Optimize applications for speed and scalability.",
  //     "Develop specifications and designs for complex applications or modifying/maintaining complex existing applications.",
  //     "Perform code analysis, requirements analysis, identification of code metrics, system risk analysis, and software reliability analysis.",
  //     "Performance tuning, improvement, balancing, usability, automation.",
  //     "Backend research & development.",
  //     "Tasks planning,management and review.",
  //   ],
  //   degree: "Bachelor's degree in Computer Science or any related major.",
  //   date: "1/1/2023",
  //   deadline: "10/12/2022",
  //   jobType: "Full Time",
  //   requirements: [
  //     "Proficient in programming using javascript.",
  //     "Proficient SQL Server (Relational Database, Stored Procedures).",
  //     "Proficient API and Web services.",
  //     "Strong understanding of object-oriented programming.",
  //     "Skill for writing reusable libraries.",
  //     "Familiar with various design and architectural patterns.",
  //     "Understanding of fundamental design principles for building a scalable application.",
  //     "Experience creating database schemas that represent and support business processes.",
  //     "Experience in implementing automated testing platforms and unit tests.",
  //     "Proficient understanding of code versioning tools such as Git, and TFS.",
  //     "MongoDB.",
  //   ],
  //   id: 6,
  // },
  // {
  //   jobTitle: "Senior Front End Developer",
  //   careerLevel: "Graduate",
  //   experience: "2-3 Years",
  //   jobDesc: [
  //     "Develop new user-facing features.",
  //     "Build reusable code and libraries for future use.",
  //     "Ensure the technical feasibility of UI/UX designs.",
  //     "Optimize applications for speed and scalability.",
  //     "Assure that all user input is validated before submitting to back-end services.",
  //     "Collaborate with other team members and stakeholders.",
  //     "Develop specifications and designs for complex applications or modifying/maintaining complex existing applications.",
  //     "Perform code analysis, requirements analysis, identification of code metrics, system risk analysis, and software reliability analysis.",
  //     "Performance tuning, improvement, balancing, usability, automation.",
  //     "Frontend research & development .",
  //     "Tasks planning,management and review.",
  //   ],
  //   degree: "Bachelor's degree in Computer Science or any related major.",
  //   date: "1/1/2023",
  //   deadline: "10/12/2022",
  //   jobType: "Full Time",
  //   requirements: [
  //     "Strong experience with HTML5, CSS3, Tailwind CSS, and JavaScript.",
  //     "Excellent with Typescript and ECMAscript.",
  //     "Solid understanding of PWA.",
  //     "Strong Experience with react.js, redux, Flutter, redux-thunk, redux-toolkit, and Zutsand.",
  //     "Experience with web services API integration.",
  //     "Good understanding of asynchronous requests and promises.",
  //     "Knowledge of authorization mechanisms, such as JWTs.",
  //     "Experience with version control such as Git.",
  //     "Knowledge of SEO principles.",
  //     "Go beyond the UI Library challenges and contribute to CI/CD improvements.",
  //     "You deeply understand DOM, CSSOM, DOM events, Critical Render Path (CRP), package distribution, client, server-side rendering, and static-site generation (CSR, SSR, SSG).",
  //     "You have experience with micro-frontends, event-driven, functional, and reactive programming.",
  //     "Strong Knowledge of Animation using CSS, and SASS.",
  //   ],
  //   id: 7,
  // },
  // {
  //   jobTitle: "Senior Software Engineer",
  //   careerLevel: "Graduate",
  //   experience: "2-3 Years",
  //   jobDesc: [
  //     "Research and developments in different application architecture layers (frontend , backend).",
  //     "Developing and improving different SDKs and NPM Packages .",
  //     "Enhancing internal development cycle.",
  //     "Building and maintaining different software governance and architecture fitness functions.",
  //     "Building and maintaining different CI/CD pipelines.",
  //   ],
  //   degree: "Bachelor's degree in Computer Science & Engineering.",
  //   date: "1/1/2023",
  //   deadline: "10/12/2022",
  //   jobType: "Full Time",
  //   requirements: [
  //     "Strong experience with HTML5, CSS3, Tailwind CSS, and JavaScript.",
  //     "Knowledge of React ]S,Flutter.",
  //     "Excellent with Javascript.",
  //     "Solid understanding of PWA.",
  //     "Strong Experience with react.js, redux, Flutter, redux-thunk, redux-toolkit, and zustand.",
  //     "Experience with web services API integration.",
  //     "Good understanding of asynchronous requests and promises.",
  //     "Experience with version control such as Git.",
  //     "Knowledge of SEO principles.",
  //     "Go beyond the UI Library challenges and contribute to CI/CD improvements.",
  //     "You have experience with micro-frontend, event-driven, functional, and reactive programming.",
  //     "Proficient in programming using javascript.",
  //     "Proficient SQL (Relational Database, Stored Procedures).",
  //     "Proficient API and Web services.",
  //     "Strong understanding of object-oriented programming.",
  //     "Skill for writing reusable libraries.",
  //     "Familiar with various design and architectural patterns.",
  //     "Understanding of fundamental design principles for building a scalable application.",
  //     "Experience creating database schemas that represent and support business processes.",
  //     "Experience in implementing automated testing platforms and unit tests.",
  //     "Proficient understanding of code versioning tools such as Git, and TFS.",
  //     "Proficient understanding of document based databases (MongoDB).",
  //     "Proficient understanding of software architecture and engineering.",
  //   ],
  //   id: 8,
  // },
  // {
  //   jobTitle: "HR Executive",
  //   careerLevel: "Graduate",
  //   experience: "1-2 Years",
  //   about:
  //     "This is a full-time opportunity with a long term commitment for HR Executives that want to gain experience in managing every aspect of the employment process, including managing internship programs, keeping track of employees’ attendance & vacations, KPI creation, and assisting with payroll cycle ",
  //   jobDesc: [
  //     "Manage Internship Programs.",
  //     "Evaluate the effectiveness of training programs.",
  //     "Oversee daily and weekly operations.",
  //     "Document and collect Employee Papers.",
  //     "Keep track of employees’ timesheet.",
  //     "Monitor and record the employees’ attendance and vacations.",
  //     "Completing the payroll cycle.",
  //     "Create and monitor Employee KPI's.",
  //     "Resources management.",
  //     "Resources acquisition.",
  //     "Monthly resources timesheet reports.",
  //   ],
  //   degree: "Human Resources Or Any Related Major",
  //   date: "19/4/2022",
  //   deadline: "19/5/2022",
  //   jobType: "Full Time",
  //   requirements: [
  //     "Bachelor's degree in human resources or any related field.",
  //     "Very good English.",
  //     "Excellent communication and interpersonal skills.",
  //     "Problem-solving and decision-making aptitude.",
  //     "Outstanding organizational and time-management abilities.",
  //     "You need to have your own laptop.",
  //     "Females Only.",
  //   ],
  //   id: 1,
  // },
  // {
  //   jobTitle: "Junior Software Engineer",
  //   careerLevel: "Graduate",
  //   experience: "0-1 Years",
  //   about:
  //     "This is a full time opportunity with a long term commitment for adventurous Developers that want to gain experience in a wide array of Technical Frameworks, interpersonal & leadership Skills, and Technical Planning.",
  //   jobDesc: [
  //     "Translating user stories into actionable services.",
  //     "Take concepts and implement them technically, plan, develop, test and debug.",
  //     "Analyzing project needs and selecting proper technologies and concepts to meet those needs.",
  //     "Enhance existing software, correct errors and develop new ones.",
  //     "Ensure quality of technical and application architecture and design of systems across the organization.",
  //     "Effectively research and benchmark technology against other best-in-class technologies.",
  //     "Self-motivator and self-starter, Ability to own and drive things without supervision, and works collaboratively with the teams across the organization.",
  //     "Have excellent soft skills and interpersonal skills to interact and present the ideas to Senior and Executive management.",
  //   ],
  //   degree: "MET, Software Development Or Any Related Major",
  //   date: "25/4/2022",
  //   deadline: "19/5/2022",
  //   jobType: "Full Time",
  //   requirements: [
  //     "MET, Software Development Or Any Related Major.",
  //     "0-1 year of experience in NodeJS, Expressjs,Flutter, and Databases (SQL and NOSQL).",
  //     "Knowledge of React JS is a plus.",
  //     "Hands-on experience in performance tuning, debugging, monitoring.",
  //     "Can Understand Complex Ideas.",
  //     "Can Communicate & Explain Complex Ideas.",
  //     "Knowledge of developing scalable and highly-available Restful APIs using NodeJS technologies.",
  //     "Understanding of Single-Sign-on or token-based authentication (JWT).",
  //     "Knowledge in following best Coding, Security, testing, and Documentation standards Good experience in Agile methodology.",
  //     "Build reusable code.",
  //     "Ensure the technical feasibility of UI/UX designs.",
  //     "Knowledge about Git and how to use it.",
  //     "Can Break Down Business Requirements Into Distinct Services",
  //   ],
  //   id: 2,
  // },
  // {
  // 	id: 1,
  // 	jobTitle: "Business Analyst",
  // 	careerLevel: "Graduate",
  // 	experience: "0-1 Years",
  // 	about:
  // 		"This is a full-time opportunity with a long term commitment for Business Analysts that want to gain experience in creating and maintaining software projects. The analyst will be assisting the Project Manager in all aspects of the business, and participate in meetings that decide the direction of the project.",
  // 	jobDesc: [
  // 		"Create Business Requirement Documents.",
  // 		"Test projects in the UAT phase.",
  // 		"Communicate with clients.",
  // 		"Create test cases for projects.",
  // 		"Keep track of the development cycle and gain technical knowledge.",
  // 		"Assist in UI/UX decisions and review the output in relation to the BRD.",
  // 		"List user stories and prioritize them",
  // 		"Assist in managing resources.",
  // 		"Solve problems that appear while developing.",
  // 		"Explain to all stakeholders the BRD and maintain communication with the Graphic Design department and Technical Department",
  // 		"Maintain and assist in relevant business aspects of projects",
  // 		"Conduct Market Research",
  // 	],
  // 	degree: "Informatics or Computer Science degrees",
  // 	date: "1/8/2022",
  // 	deadline: "8/8/2022",
  // 	jobType: "Full Time",
  // 	requirements: [
  // 		"Bachelor's degree in Business Informatics, Computer Science, or any other related degrees.",
  // 		"Very good English.",
  // 		"Excellent communication and interpersonal skills.",
  // 		"Problem-solving and decision-making aptitude.",
  // 		"Outstanding organizational and time-management abilities.",
  // 		"You need to have your own laptop.",
  // 	],
  // },
  // {
  // 	id: 2,
  // 	jobTitle: "Accountant",
  // 	careerLevel: "Graduate",
  // 	experience: "2-4 Years",
  // 	about:
  // 		"This is a full-time opportunity with a long term commitment for an Accountant that wants to gain experience in helping the company make financial decisions, including collecting, tracking, correcting, and computing taxes.",
  // 	jobDesc: [
  // 		"Compiling, analyzing, and reporting financial data.",
  // 		"Understanding of mathematics and accounting and financial processes.",
  // 		"Handling monthly, quarterly and annual closings.",
  // 		"Creating periodic reports, such as balance sheets and profit statements.",
  // 		"Maintaining accurate financial records",
  // 		"Preparing budgets and financial forecasts",
  // 		"Computing taxes and preparing tax returns.",
  // 	],
  // 	degree: "Bachelor's degree in Accounting or related major.",
  // 	date: "11/8/2022",
  // 	deadline: "18/8/2022",
  // 	jobType: "Full Time",
  // 	requirements: [
  // 		"Bachelor's degree in Accounting or related field.",
  // 		"Very good English.",
  // 		"Strong analytical, communication, and computer skills.",
  // 		"Problem-solving and decision-making aptitude",
  // 	],
  // },
  // {
  //   jobTitle: "Senior Business Developer",
  //   careerLevel: "Graduate",
  //   experience: "2-4 Years",
  //   about:
  //     "This is a full time opportunity with a long term commitment for a senior business developer who coordinate and implement sales plans designed to maintain and increase existing business and capture new opportunities.",
  //   jobDesc: [
  //     "Advertising experience is a must.",
  //     "Develop new business and long term account opportunities through prospecting and cold calling.",
  //     "Develop, coordinate and implement sales plans designed to maintain and increase existing business and capture new opportunities.",
  //     "Develop sales presentations and participate in meetings with clients.",
  //     "Complete RFQs and proposals, follow up on submissions and close business deals.",
  //     " Be aware of organizational growth initiatives regarding market and target customer segments, and the company’s potential to meet customer needs.",
  //     " Oversee, maintain and upgrade existing accounts to ensure ongoing client satisfaction.",
  //     " Coordinate, manage and resolve account issues.",
  //     " Benchmark state-of-the-art practices to recommend profit and service improvements.",
  //     " Negotiating and closing deals.",
  //     " Following up with the clients for revenue collection.",
  //     " Reporting progress to the MD.",
  //     " Researching active customers in the market.",
  //     " Drive efforts with product and business development to define an overall product and technology portfolio strategy that will drive the long-term growth of the organization.",
  //     " Analyze existing products and markets in terms of the ability to compete, gain market share, grow revenue and sustain or grow profitability.",
  //     " Monitors business metrics in regard to revenues, profitability, and market share and product portfolio mix. Communicate these metrics to the senior management team.",
  //     " Collaborate with Sales, Marketing to define effective sales channels of all products.",
  //     " Establish an effective relationship with the sales force with leadership, training, pricing and positioning expertise.",
  //     " Responsible (in collaboration with sales) for the development of the company’s revenue forecast.",
  //     " Experience with inbound and outbound marketing, design and implement marketing initiatives including newsletter, trade-shows, webinars, collateral, social media.",
  //   ],
  //   degree: "Bachelor of business or any Related Major.",
  //   date: "24/10/2022",
  //   deadline: "31/10/2022",
  //   jobType: "Full Time",
  //   requirements: [
  //     "Bachelor of business or any Related Major.",
  //     "Excellent communication and negotiation skills.",
  //     "Great time management skills are essential.",
  //     "Be exceptionally well presented.",
  //     "Be a seasoned negotiator.",
  //     "Very good English.",
  //     "Adept at Problem solving.",
  //     "BSc/BA in Business or relevant field; MSc/MA is a plus.",
  //     "Problem-solving aptitude.",
  //     "Experience in delivering client-focused solutions based on customer needs.",
  //     "Excellent listening, negotiation and presentation abilities.",
  //     "Self-motivated, dynamic, flexible and presentable.",
  //     "Excellent use of Microsoft Office",
  //     "Solid knowledge of performance reporting and financial/budgeting processes",
  //   ],
  //   id: 1,
  // },
  // {
  //   jobTitle: "Senior Software Engineer",
  //   careerLevel: "Graduate",
  //   experience: "2-4 Years",
  //   about:
  //     "This is a full time opportunity with a long term commitment for adventurous Developers that want to gain experience in a wide array of Technical Frameworks, interpersonal & leadership Skills, and Technical Planning.",
  //   jobDesc: [
  //     "Translating user stories into actionable services.",
  //     "Take concepts and implement them technically, plan, develop, test and debug.",
  //     "Analyzing project needs and selecting proper technologies and concepts to meet those needs.",
  //     "Enhance existing software, correct errors and develop new ones.",
  //     " Ensure quality of technical and application architecture and design of systems across the organization.",
  //     " Effectively research and benchmark technology against other best-in-class technologies.",
  //     " Self-motivator and self-starter, Ability to own and drive things without supervision, and work collaboratively with the teams across the organization.",
  //     " Have excellent soft skills and interpersonal skills to interact and present the ideas to Executive management.",
  //     " Have the ability and experience to support a team of 2-4 junior software engineers.",
  //   ],
  //   degree: "Bachelor of engineering or any Related Major.",
  //   date: "24/10/2022",
  //   deadline: "31/10/2022",
  //   jobType: "Full Time",
  //   requirements: [
  //     "Bachelor of engineering or any Related Major.",
  //     "Minimum 2 year of experience in JavaScript, NodeJs, ExpressJs, and Databases (SQL and NOSQL).",
  //     "Knowledge of React ]S,Flutter.",
  //     "Hands-on experience in performance tuning, debugging, monitoring.",
  //     "Can Communicate & Explain Complex Ideas.",
  //     "Knowledge of developing scalable and highly-available Restful APIs using Node]S technologies.",
  //     "Understanding of Single-Sign-on or token-based authentication (JWT).",
  //     "Knowledge in following best Coding, Security, testing, and Documentation standards Good experience in Agile methodology.",
  //     "Build reusable code.",
  //     "Ensure the technical feasibility of Ul/UX designs.",
  //     "Knowledge about Git and how to use it and manage it with a team.",
  //     "Can Break Down Business Requirements Into Distinct Services.",
  //     "Understanding of Microservice Architecture",
  //   ],
  //   id: 2,
  // },
];

module.exports = { vacancies };
