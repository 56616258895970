import React, { Component } from "react";
import "../stylesheets/header.css";
import { Container, Button, Row, Col, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import DropdownToggle from "react-bootstrap/DropdownToggle";
export default class Header extends Component {
  constructor(props) {
    super(props);

    this.scrollExplore = this.scrollExplore.bind(this);
  }

  scrollExplore = (e) => {
    e.preventDefault();
    this.props.handleScrollExplore();
  };
  render() {
    return (
      <div className="header">
        {" "}
        <div className="pt-5 mobileheader">
          {" "}
          <Link to="/">
            <picture>
              <source
                srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/headerlogo.webp"
                alt="lirtenHeader"
                type="image/webp"
              />
              <source
                srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/logo.png"
                alt="lirtenHeader"
                type="image/png"
              />{" "}
              <img src="img/creakyOldJPEG.jpg" alt="lirtenHeader" />
            </picture>{" "}
          </Link>
          <Dropdown className="headmenu3">
            <DropdownToggle className="jointoggle joinLink px-2">
              {" "}
              JOIN OUR TEAM
            </DropdownToggle>
            <Dropdown.Menu className="headmenu2">
              <Dropdown.Item className="joinLink joinitem joinitem2">
                {" "}
                <Link to="/joinOurTeam" className="joinButton joinButton1">
                  hiring{" "}
                </Link>{" "}
              </Dropdown.Item>
              <Dropdown.Item className="joinLink joinitem joinitem3">
                {" "}
                <Link to="/internship" className="joinButton joinButton2">
                  internshsip{" "}
                </Link>{" "}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <span
            className="px-5"
            style={{ color: '#d0ad5e', fontWeight: 'bold', float: 'right' }}
          >
            JOIN OUR TEAM
          </span> */}
        </div>
        <div className="clearfix"></div>
        <div className="m-auto pt-3 text-center">
          {" "}
          <h1> digital made tangable</h1>
        </div>
        <Container className="title m-auto pt-4 text-center">
          <Row>
            <div className="m-auto text-center subhead">
              {" "}
              <p>
                We facilitate the technology businesses need to make better
                descisions in the technological, mobile era.
              </p>
            </div>
          </Row>
          <Row className="buttonRow">
            <Col className="m-auto text-center" sm={12}>
              {" "}
              <Button onClick={this.scrollExplore}>explore the success</Button>
            </Col>
            {/* <Col className="m-auto text-center" sm={12}>
              {' '}
              <Button onClick={this.scrollExplore}>JOIN US</Button>
            </Col> */}
          </Row>
        </Container>
        <div className="socials">
          {" "}
          <a
            href="https://www.linkedin.com/company/software-company-lirten-solutions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <i className="fab fa-linkedin pr-2"></i>
          </a>
          <a
            href="https://twitter.com/LirtenSolutions"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <i className="fab fa-twitter-square pr-2"></i>
          </a>
          <a
            href="https://www.facebook.com/LirtenSolutions"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <i className="fab fa-facebook-square pr-2"></i>
          </a>
          {/* here will be instagram link */}
          <a
            href="https://www.instagram.com/lirten_solutions/?igshid=MzNlNGNkZWQ4Mg%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <i
              className="fab fa-instagram "
              // style={{
              //   borderRadius: "5px",
              //   backgroundColor: "white",
              //   color: "black",
              //   maxWidth: "fit-content",
              //   maxHeight: "fit-content",
              // }}
            ></i>
          </a>
        </div>
      </div>
    );
  }
}
