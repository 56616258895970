import React, { Component } from "react";
import {
  Container,
  Form,
  Col,
  Row,
  Button,
  Dropdown,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ReactDatez } from "react-datez";
import "react-datez/dist/css/react-datez.css";
import isEqual from "lodash/isEqual";
import "../../stylesheets/Form.css";
import { emailLink } from "../../../config.js";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class JoinusFrorm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      employed: "false",
      show: false,
      number: "",

      jobTitle: this.props.newTitle,
      id: this.props.id,
      name: "",
      date: "",
      degree: "",
      email: "",
      cv: "",
      position: "",
      employer: "",
      file: null,
      errName: "",
      errMail: "",

      errDegree: "",
      errPosition: "",
      errEmp: "",
      FEError: "",
      datefrom: "",
      BEError: "",
      jobError: "",
    };
    this.getTitle = this.getTitle.bind(this);
    this.getId = this.getId.bind(this);
  }

  setFile = (e) => {
    let file = e.target.files[0];
    this.setState({ file: file, FEError: "" });
  };
  getTitle = (title) => {
    // this.setState({ title: e.target.id });
    this.props.getTitle(title);
  };
  getId = (id) => {
    // this.setState({ title: e.target.id });
    this.props.getId(id);
  };

  handleUserName = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.match(/(.*[a-z]){3}/i)) {
      this.setState({ name: e.target.value });
      this.setState({ errName: "" });
    } else {
      this.setState({ errName: "name must be min 3 char" });
    }
  };

  handleDegree = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.match(/(.*[a-z]){3}/i)) {
      this.setState({ degree: e.target.value });
      this.setState({ errDegree: "" });
    } else {
      this.setState({ errDegree: "please enter valid Scientific Degree " });
    }
  };
  handleChangestartdate = (value) => {
    this.setState({ datefrom: value.split("T")[0] });
  };
  handleEmail = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      this.setState({ email: e.target.value });
      this.setState({ errEmail: "" });
    } else {
      this.setState({ errEmail: "please enter valid Email " });
    }
  };

  handlePosition = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.match(/(.*[a-z]){3}/i)) {
      this.setState({ position: e.target.value });
      this.setState({ errPosition: "" });
    } else {
      this.setState({ errPosition: "please enter valid position " });
    }
  };
  handleEmployer = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.match(/(.*[a-z]){3}/i)) {
      this.setState({ employer: e.target.value });
      this.setState({ errEmp: "" });
    } else {
      this.setState({ errEmp: "please enter valid Employer " });
    }
  };

  handleItemClick = (e) => {};

  handleSelect = (eventKey, id) => {
    this.setState({ jobTitle: eventKey, jobError: "", id: id });
    this.getTitle(eventKey);
    this.getId(id);
  };

  handleCheckboxChange = (e) => {
    let position = this.state.position;
    let employer = this.state.employer;
    let employed = this.state.employed;
    if (employed !== "true") {
      employed = "true";
    } else {
      employed = "false";
      position = "";
      employer = "";
    }
    this.setState({
      employed,
    });
    this.setState({
      employer,
    });
    this.setState({
      position,
    });
  };

  onRegist = async (e) => {
    e.preventDefault();
    // console.log('el id', this.state.jobTitle, this.state.id);
    if (this.state.jobTitle === "List of Vacancies*") {
      this.setState({
        jobError: "Please select a vacancy first",
        FEError: "please fill out all required fields",
      });
    } else if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.number === "" ||
      this.state.degree === "" ||
      this.state.datefrom == "" ||
      this.state.file === null
    ) {
      this.setState({ FEError: "please fill out all required fields" });
    } else {
      if (
        this.state.employed == "true" &&
        (this.state.employer === "" || this.state.position === "")
      ) {
        this.setState({ FEError: "please fill out all required fields" });
      } else if (
        this.state.employed == "false" &&
        (this.state.errName !== "" ||
          this.state.errDegree !== "" ||
          this.state.errEmail !== "")
      ) {
        this.setState({ FEError: "please enter valid data" });
      } else if (
        this.state.employed == "true" &&
        (this.state.errName !== "" ||
          this.state.errDegree !== "" ||
          this.state.errEmail !== "" ||
          this.state.errPosition !== "" ||
          this.state.errEmp !== "")
      ) {
        this.setState({ FEError: "please enter valid data" });
      } else {
        this.setState({ FEError: "" });

        const eventdata = new FormData();
        eventdata.append("receiverEmail[0]", "magy.elbadri@lirten.com");
        eventdata.append(
          "subject",
          `Lirten-New Job Application: ${this.state.name}`
        );
        eventdata.append("password", "mpN'y5{jN,9q{H4:");
        // eventdata.append('cc', null);
        // eventdata.append('bcc', null);

        if (this.state.employed) {
          eventdata.append(
            "body",
            `A job application with the following information has been sent to Lirten \n\t Job Title: ${this.state.jobTitle}  \n\t Name: ${this.state.name}   \n\t Scientific Degree: ${this.state.degree} \n\t Email: ${this.state.email}  \n\t Number: ${this.state.number}    \n\t Birth date: ${this.state.datefrom}\n\t Current Position: ${this.state.position}  \n\t Current Employer: ${this.state.employer} \n Sincerly, Lirten bot`
          );
        } else {
          eventdata.append(
            "body",
            `A job application with the following information has been sent to Lirten  \n\t Job Title: ${this.state.jobTitle}  \n\t Name: ${this.state.name}  \n\t Scientific Degree: ${this.state.degree} \n\t Email: ${this.state.email}  \n\t Number: ${this.state.number}    \n\t birth date: ${this.state.datefrom}\n Sincerly, Lirten bot`
          );
        }
        let x = true;
        if (this.state.file) {
          if (this.state.file.size <= 1024 * 1024) {
            eventdata.append("file1", this.state.file);
          } else {
            x = false;
            this.setState({ FEError: "Max file size 1MB" });
          }
        }
        if (x) {
          axios({
            method: "post",
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: eventdata,
            url: emailLink,
          })
            .then((res) => {
              if (res.data.exitCode === 0) {
                console.log("ok");
                this.setState({
                  show: true,
                  errors: {},
                  number: "",
                  name: "",
                  date: "",
                  degree: "",
                  email: "",
                  cv: "",
                  position: "",
                  employer: "",
                  errName: "",
                  errMail: "",
                  errDegree: "",
                  errPosition: "",
                  errEmp: "",
                  FEError: "",
                  file: "",
                  datefrom: "",
                });
                setTimeout(() => {
                  this.setState({ show: false });
                }, 1300);
              } else {
                console.log("no");
                this.setState({
                  BEError: "an error occurred please try again",
                });
              }
            })

            .catch((err) => {
              console.log(err);
              this.setState({ BEError: "an error occurred try again" });
            });
        }
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (
        this.state.name !== "" &&
        this.state.number !== "" &&
        this.state.degree !== "" &&
        this.state.file !== null &&
        this.state.file.size <= 1024 * 1024 &&
        this.state.email !== "" &&
        this.state.employed == "false" &&
        this.state.errName == "" &&
        this.state.errDegree == "" &&
        this.state.errEmail == ""
      ) {
        this.setState({ FEError: "" });
      } else if (
        this.state.name !== "" &&
        this.state.number !== "" &&
        this.state.degree !== "" &&
        this.state.file !== null &&
        this.state.file.size <= 1024 * 1024 &&
        this.state.email !== "" &&
        this.state.employed == "true" &&
        this.state.position !== "" &&
        this.state.employer !== "" &&
        this.state.errName == "" &&
        this.state.errDegree == "" &&
        this.state.errEmail == "" &&
        this.state.errPosition == "" &&
        this.state.errEmp == ""
      ) {
        this.setState({ FEError: "" });
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ jobTitle: nextProps.newTitle, id: nextProps.id });
  }

  render() {
    const titles = this.props.detailsList.map((job) => {
      return (
        <Dropdown.Item
          onSelect={() => this.handleSelect(job.jobTitle, job.id)}
          eventKey={job.jobTitle}
          id={job.id}
          style={{ color: "white" }}
          // key={job.jobTitle}
        >
          {job.jobTitle}
        </Dropdown.Item>
      );
    });
    return (
      <div className="background p-5 mt-5 mb-5">
        <Row>
          <Col sm={12} md={12}>
            <div className="contactus mb-5">
              <Container className="contactsections joinform">
                <Row>
                  <Col sm={12} md={8}>
                    <form
                      id="apply"
                      onSubmit={this.onRegist}
                      noValidate
                      className="offset-lg-1"
                    >
                      <Container>
                        {" "}
                        <p className="applyNow">APPLY NOW </p>
                        <Dropdown>
                          <Dropdown.Toggle
                            // variant="success"
                            id="dropdown-basic"
                            className="vac contactForm mb-4 customButton"
                          >
                            {this.state.jobTitle}
                            <i
                              className="fas fa-angle-double-down"
                              style={{ float: "right", paddingTop: "4px" }}
                            ></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>{titles}</Dropdown.Menu>
                        </Dropdown>
                        {this.state.jobError ? (
                          <span
                            style={{ fontSize: "13px" }}
                            className="errorMsg"
                          >
                            {" "}
                            {this.state.jobError}
                          </span>
                        ) : null}
                        <Form.Control
                          noValidate
                          required
                          onKeyDown={this.enter}
                          type="text"
                          onChange={this.handleUserName}
                          value={this.state.name}
                          name="name"
                          className="contactForm mb-4 nameborder fields"
                          placeholder="Full Name*"
                        />
                        {this.state.errName ? (
                          <span
                            style={{ fontSize: "13px" }}
                            className="errorMsg"
                          >
                            {" "}
                            {this.state.errName}
                          </span>
                        ) : null}
                        <ReactDatez
                          placeholder="Birth Date*"
                          name="datefrom"
                          handleChange={this.handleChangestartdate}
                          value={this.state.datefrom}
                          allowPast={true}
                          inputStyle={{
                            backgroundColor: "#060157",
                            color: "white",
                            outline: "none",
                          }}
                          className="date"
                        />
                        <div className="errorMsg">
                          {this.state.errors.birthdate}
                        </div>
                        <Form.Control
                          noValidate
                          required
                          onKeyDown={this.enter}
                          type="text"
                          onChange={this.handleDegree}
                          value={this.state.degree}
                          name="degree"
                          className="contactForm mb-4 nameborder fields"
                          placeholder="Scientific Degree*"
                        />
                        {this.state.errDegree ? (
                          <span
                            style={{ fontSize: "13px" }}
                            className="errorMsg"
                          >
                            {" "}
                            {this.state.errDegree}
                          </span>
                        ) : null}
                        <Form.Control
                          noValidate
                          required
                          type="email"
                          value={this.state.email}
                          onChange={this.handleEmail}
                          name="email"
                          placeholder="E-Mail*"
                          className="contactForm my-4 nameborder fields"
                        />{" "}
                        {this.state.errEmail ? (
                          <span
                            style={{ fontSize: "13px" }}
                            className="errorMsg"
                          >
                            {" "}
                            {this.state.errEmail}
                          </span>
                        ) : null}
                        <PhoneInput
                          country={"eg"}
                          value={this.state.number}
                          onChange={(number) => this.setState({ number })}
                          inputStyle={{
                            backgroundColor: "#060157",
                            color: "white",
                            outline: "none",
                          }}
                        />
                        <div className="my-4">
                          <div className="errorMsg">
                            {this.state.errors.number}
                          </div>
                        </div>
                        {/* <p style={{ color: "white" }} className="p-1 m-1 ">
                          Upload your CV*
                        </p> */}
                        <div className="contactForm mb-4">
                          {/* <Button
                            variant="contained"
                            component="label"
                            style={{ height: "7vh" }}
                          >
                            <input
                              onChange={this.setFile}
                              type="file"
                              style={{ color: "white", border: "none" }}
                            />
                          </Button> */}
                          <div className="upload-btn-wrapper">
                            <button
                              className="Formbtn"
                              variant="contained"
                              component="label"
                            >
                              <i
                                className="fas fa-upload"
                                style={{ paddingRight: "20px" }}
                              ></i>
                              Click Here To Upload Your CV*
                            </button>
                            <input
                              onChange={this.setFile}
                              type="file"
                              style={{ color: "white", border: "none" }}
                            />
                          </div>

                          {this.state.file !== null ? (
                            <p style={{ color: "white" }} className="p-1 m-1 ">
                              {" "}
                              {this.state.file ? this.state.file.name : ""}
                            </p>
                          ) : null}
                        </div>
                        <div className="errorMsg">{this.state.errors.cv}</div>
                        <div key={`default-${"checkbox"}`} className="mb-3">
                          <Form.Check
                            type={"checkbox"}
                            id={`alreadyEmployed`}
                            label={`Already Employed?`}
                            style={{ color: "white" }}
                            className="my-4 "
                            onChange={this.handleCheckboxChange}
                          />
                        </div>
                        {this.state.employed !== "false" ? (
                          <div>
                            <Form.Control
                              noValidate
                              required
                              type="text"
                              value={this.state.position}
                              onChange={this.handlePosition}
                              name="position"
                              placeholder="Current Positon*"
                              className="contactForm mb-4 nameborder fields"
                            />
                            {this.state.errPosition ? (
                              <span
                                style={{ fontSize: "13px" }}
                                className="errorMsg"
                              >
                                {" "}
                                {this.state.errPosition}
                              </span>
                            ) : null}

                            <Form.Control
                              noValidate
                              required
                              type="text"
                              value={this.state.employer}
                              onChange={this.handleEmployer}
                              name="employer"
                              placeholder="Current Employer*"
                              className="contactForm mb-4 nameborder fields"
                            />
                            {this.state.errEmp ? (
                              <span
                                style={{ fontSize: "13px" }}
                                className="errorMsg"
                              >
                                {" "}
                                {this.state.errEmp}
                              </span>
                            ) : null}
                          </div>
                        ) : null}
                        <Col sm={12}>
                          <Row className="contactfields">
                            <Col sm={9}>
                              <p
                                style={{
                                  fontSize: "10px",
                                  color: "#AAA",
                                  fontWeight: "500",
                                  // position: 'absolute',
                                  left: "0",
                                }}
                              >
                                * fields marked with an asterisk (*) are
                                required to fullfil the form. failing to
                                complete any of those fields will result in a
                                failure to submit your request accompanied by a
                                failure message.
                              </p>
                            </Col>
                            <Col sm={3}>
                              {" "}
                              <Button
                                for="apply"
                                type="submit"
                                className="submitButton"
                              >
                                SUBMIT
                              </Button>
                            </Col>
                          </Row>
                          {this.state.FEError ? (
                            <span className="BbachError">
                              {" "}
                              {this.state.FEError}
                            </span>
                          ) : null}
                          {this.state.BEError ? (
                            <span className="BbachError">
                              {" "}
                              <br /> {this.state.BEError}
                            </span>
                          ) : null}
                        </Col>
                      </Container>
                    </form>
                  </Col>
                  <Col sm={12} md={3} className="mt-5 contactnote formnote">
                    <img
                      alt="dotsapproach"
                      src="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/rect.svg"
                      className="notepic"
                    />
                    <h6>note:</h6>
                    <hr className="applynote-hr" />
                    <p>
                      we will be sending
                      <br className="d-none d-xl-block" /> you an e-mail{" "}
                      <br className="d-none d-xl-block" /> shortly with all{" "}
                      <br className="d-none d-xl-block" /> the details we need{" "}
                      <br className="d-none d-xl-block" /> to start on your
                      <br className="d-none d-xl-block" /> next big project. if{" "}
                      <br className="d-none d-xl-block" /> you’re in a hurry,
                      <br className="d-none d-xl-block" /> you could always{" "}
                    </p>
                    <br />
                    {/* <p>
                      call us on <br className="d-none d-xl-block" />
                  +2 01003388470 or
                </p> */}
                    <p>
                      {" "}
                      e-mail us on
                      <br className="d-none d-xl-block" /> hiring@lirten.com
                    </p>
                  </Col>
                </Row>{" "}
                <Modal className="mt-2" show={this.state.show}>
                  <div id="snackbar">Sent Successfully</div>
                </Modal>
              </Container>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default JoinusFrorm;
