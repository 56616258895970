import React, { Component } from "react";
import {
  Container,
  Form,
  Col,
  Row,
  Button,
  Dropdown,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ReactDatez } from "react-datez";
import "react-datez/dist/css/react-datez.css";
import isEqual from "lodash/isEqual";
import "../../stylesheets/Form.css";
import { emailLink } from "../../../config.js";

class Internform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      errors: {},
      jobTitle: "Intership Program",
      name: "",
      email: "",
      university: "",
      gpa: "GPA*",
      cv: "",
      year: "Graduation Year*",
      program: "Program*",
      file: null,
      errName: "",
      errMail: "",
      errUniversity: "",
      errYear: "",
      FEError: "",
      datefrom: "",
      BEError: "",
      programError: "",
      gpaError: "",
    };
  }

  setFile = (e) => {
    let file = e.target.files[0];
    this.setState({ file: file });
  };
  handleUserName = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.match(/(.*[a-z]){3}/i)) {
      this.setState({ name: e.target.value });
      this.setState({ errName: "" });
    } else {
      this.setState({ errName: "name must be min 3 char" });
    }
  };
  // handleID = (e) => {
  //   this.setState({ [e.target.name]: e.target.value })
  //   if (e.target.value.match(/(((.*[0-9]){2})+)-([0-9])/i)) {
  //     this.setState({ id: e.target.value })
  //     this.setState({ errID: '' })
  //   } else {
  //     this.setState({ errID: 'ID must be in this format XX-XXXX' })
  //   }
  // }

  handleChangestartdate = (value) => {
    this.setState({ datefrom: value.split("T")[0] });
  };
  handleEmail = (e) => {
    // if (e.target.value.includes("@student.guc.edu.eg")) {
    // this.setState({ [e.target.name]: e.target.value })
    // if (e.target.value.match(/^([\w.%+-]+)$/i)) {
    this.setState({ email: e.target.value });
    this.setState({ errEmail: "" });
    // } else {
    //   this.setState({ errEmail: 'Please Enter Valid Username ' })
    // }
    // }
    // else {
    //   this.setState({ [e.target.name]: e.target.value + "@student.guc.edu.eg" });
    //   if (e.target.value.match(/^[a-zA-Z0-9._-]$/i)) {
    //     this.setState({ email: e.target.value + "@student.guc.edu.eg" });
    //     this.setState({ errEmail: "" });
    //   } else {
    //     this.setState({ errEmail: "please enter valid Email " });
    //   }
    // }
  };

  handleUniversity = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.match(/(.*[a-z]){3}/i)) {
      this.setState({ university: e.target.value });
      this.setState({ errUniversity: "" });
    } else {
      this.setState({ errUniversity: "University Name must min 3 characters" });
    }
  };

  handleItemClick = (e) => {};
  handleYearSelect = (eventKey) => {
    this.setState({ year: eventKey, yearError: "" });
  };

  handleGPASelect = (eventKey) => {
    this.setState({ gpa: eventKey, gpaError: "" });
  };

  handleProgramSelect = (eventKey) => {
    this.setState({ program: eventKey, programError: "" });
  };

  onRegist = async (e) => {
    e.preventDefault();
    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.university === "" ||
      this.state.year === "" ||
      this.state.date === ""
    ) {
      this.setState({ FEError: "please fill out all required fields" });
    } else if (this.state.program === "Program*") {
      this.setState({ programError: "Please choose a program" });
    } else if (this.state.year === "Graduation Year*") {
      this.setState({ yearError: "Please choose a year" });
    } else if (this.state.gpa === "GPA*") {
      this.setState({ gpaError: "Please choose a GPA" });
    } else if (this.state.file === null) {
      this.setState({ FEError: "please upload a CV" });
    } else if (
      this.state.errName !== "" ||
      this.state.errEmail !== "" ||
      this.state.errYear !== ""
    ) {
      this.setState({ FEError: "please enter valid data" });
    } else {
      this.setState({ FEError: "" });

      const eventdata = new FormData();

      eventdata.append("receiverEmail", "internship@lirten.com");
      eventdata.append(
        "subject",
        `Lirten-New Job Application: ${this.state.id}`
      );
      eventdata.append(
        "body",
        `A job application with the following information has been sent to Lirten \n\t Job Title: ${
          this.state.jobTitle
        }  \n\t Name: ${this.state.name}   \n\t Email: ${
          this.state.email + "@student.guc.edu.eg"
        }   \n\t Birth date: ${this.state.datefrom}  \n\t ID: ${
          this.state.id
        } \n\t Round: ${this.state.round} \n Sincerly, Lirten bot`
      );
      eventdata.append("password", "mpN'y5{jN,9q{H4:");
      eventdata.append("cc", null);
      eventdata.append("bcc", null);

      if (this.state.file) {
        eventdata.append("file1", this.state.file);
      }

      for (var [key, value] of eventdata.entries()) {
        console.log(key, value);
      }
      axios({
        method: "post",
        data: eventdata,
        url: emailLink,
      })
        .then((res) => {
          console.log(res);
          if (res.data.header.statusCode === "0000") {
            console.log("ok");
            this.setState({
              show: true,
              errors: {},
              jobTitle: "Intership Program",
              name: "",
              email: "",
              university: "",
              gpa: "GPA*",
              cv: "",
              year: "Graduation Year*",
              file: null,
              errName: "",
              errMail: "",
              errUniversity: "",
              errYear: "",
              program: "Program*",
              FEError: "",
              gpaError: "",
              datefrom: "",
              BEError: "",
            });
            document.getElementById("apply").reset();
            setTimeout(() => {
              this.setState({ show: false });
            }, 1300);
          } else {
            console.log("no");
            this.setState({ BEError: "an error occurred please try again" });
          }
        })

        .catch((err) => {
          console.log(err.response);
          this.setState({ BEError: "an error occurred try again" });
        });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (
        this.state.name !== "" &&
        this.state.datefrom !== "" &&
        this.state.file !== null &&
        this.state.email !== "" &&
        this.state.university !== "" &&
        this.state.gpa !== "GPA*" &&
        this.state.year !== "Graduation Year*" &&
        this.state.program !== "Program*" &&
        this.state.errName == "" &&
        this.state.errEmail == "" &&
        this.state.errUniversity !== "" &&
        this.state.gpaError !== "" &&
        this.state.programError !== "" &&
        this.state.errYear == ""
      ) {
        this.setState({ FEError: "" });
      }
    }
  }

  render() {
    return (
      <div className="background p-5 mt-5 mb-5">
        <Row>
          <Col sm={12} md={12}>
            <div className="contactus mb-5">
              <Container className="contactsections joinform">
                <Row>
                  <Col sm={12} md={8}>
                    <form
                      id="apply"
                      onSubmit={this.onRegist}
                      noValidate
                      className="offset-lg-1"
                    >
                      <Container>
                        {" "}
                        <p className="applyNow">APPLY NOW </p>
                        <Form.Control
                          noValidate
                          required
                          onKeyDown={this.enter}
                          type="text"
                          onChange={this.handleUserName}
                          value={this.state.name}
                          name="name"
                          className="contactForm mb-4 nameborder fields"
                          placeholder="Full Name*"
                        />
                        {this.state.errName ? (
                          <span
                            style={{ fontSize: "13px" }}
                            className="errorMsg"
                          >
                            {" "}
                            {this.state.errName}
                          </span>
                        ) : null}
                        <ReactDatez
                          placeholder="Birth Date*"
                          name="datefrom"
                          handleChange={this.handleChangestartdate}
                          value={this.state.datefrom}
                          allowPast={true}
                          inputStyle={{
                            backgroundColor: "#060157",
                            color: "white",
                            outline: "none",
                          }}
                          className="date"
                        />
                        <div className="errorMsg">
                          {this.state.errors.birthdate}
                        </div>
                        {/* <Form.Control
                          noValidate
                          required
                          type="email"
                          value={this.state.email}
                          onChange={this.handleEmail}
                          name="email"
                          placeholder="GUC E-Mail*"
                          className="contactForm my-4 nameborder fields"
                        />{" "} */}
                        <div
                          className="contactForm my-4 nameborder fields"
                          style={{ padding: "2px" }}
                        >
                          <input
                            value={this.state.email}
                            placeholder="Email*"
                            className="fields"
                            type="text"
                            autocomplete="username"
                            onChange={this.handleEmail}
                            spellcheck="false"
                            tabindex="0"
                            aria-label="Username"
                            name="email"
                            id="username"
                            data-initial-value=""
                            style={{
                              height: "100%",
                              width: "50%",
                              border: "none",
                            }}
                          />
                          {/* <span className='GUCemail'>@student.guc.edu.eg</span> */}
                        </div>
                        {this.state.errEmail ? (
                          <span
                            style={{ fontSize: "13px" }}
                            className="errorMsg"
                          >
                            {" "}
                            {this.state.errEmail}
                          </span>
                        ) : null}
                        <div
                          className="contactForm my-4 nameborder fields"
                          style={{ padding: "2px" }}
                        >
                          <input
                            value={this.state.university}
                            placeholder="University*"
                            className="fields"
                            type="text"
                            autocomplete="username"
                            onChange={this.handleUniversity}
                            spellcheck="false"
                            tabindex="0"
                            aria-label="Username"
                            name="university"
                            id="university"
                            data-initial-value=""
                            style={{
                              height: "100%",
                              width: "50%",
                              border: "none",
                            }}
                          />
                        </div>
                        {this.state.errUniversity ? (
                          <span
                            style={{ fontSize: "13px" }}
                            className="errorMsg"
                          >
                            {" "}
                            {this.state.errUniversity}
                          </span>
                        ) : null}
                        <Dropdown>
                          <Dropdown.Toggle
                            // variant="success"
                            id="dropdown-basic"
                            className="vac contactForm mb-4 customButton"
                          >
                            {this.state.gpa}
                            <i
                              className="fas fa-angle-double-down"
                              style={{ float: "right", paddingTop: "4px" }}
                            ></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {" "}
                            <Dropdown.Item
                              onSelect={this.handleGPASelect}
                              eventKey="Fair"
                              style={{ color: "white" }}
                              // key={job.jobTitle}
                            >
                              Fair
                            </Dropdown.Item>
                            <Dropdown.Item
                              onSelect={this.handleGPASelect}
                              eventKey="Good"
                              style={{ color: "white" }}
                              // key={job.jobTitle}
                            >
                              Good
                            </Dropdown.Item>
                            <Dropdown.Item
                              onSelect={this.handleGPASelect}
                              eventKey="Very Good"
                              style={{ color: "white" }}
                              // key={job.jobTitle}
                            >
                              Very Good
                            </Dropdown.Item>
                            <Dropdown.Item
                              onSelect={this.handleGPASelect}
                              eventKey="Excellent"
                              style={{ color: "white" }}
                              // key={job.jobTitle}
                            >
                              Excellent
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <Form.Control
                          noValidate
                          required
                          onKeyDown={this.enter}
                          type='text'
                          onChange={this.handleID}
                          value={this.state.id}
                          name='id'
                          className='contactForm mb-4 nameborder fields'
                          placeholder='Uni Year*'
                        />
                        {this.state.errID ? (
                          <span
                            style={{ fontSize: '13px' }}
                            className='errorMsg'
                          >
                            {' '}
                            {this.state.errID}
                          </span>
                        ) : null} */}
                        <Dropdown>
                          <Dropdown.Toggle
                            // variant="success"
                            id="dropdown-basic"
                            className="vac contactForm mb-4 customButton"
                          >
                            {this.state.year}
                            <i
                              className="fas fa-angle-double-down"
                              style={{ float: "right", paddingTop: "4px" }}
                            ></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {" "}
                            <Dropdown.Item
                              onSelect={this.handleYearSelect}
                              eventKey="2022"
                              style={{ color: "white" }}
                              // key={job.jobTitle}
                            >
                              2022
                            </Dropdown.Item>
                            <Dropdown.Item
                              onSelect={this.handleYearSelect}
                              eventKey="2023"
                              style={{ color: "white" }}
                              // key={job.jobTitle}
                            >
                              2023
                            </Dropdown.Item>
                            <Dropdown.Item
                              onSelect={this.handleYearSelect}
                              eventKey="Graduated"
                              style={{ color: "white" }}
                              // key={job.jobTitle}
                            >
                              Graduated
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle
                            // variant="success"
                            id="dropdown-basic"
                            className="vac contactForm mb-4 customButton"
                          >
                            {this.state.program}
                            <i
                              className="fas fa-angle-double-down"
                              style={{ float: "right", paddingTop: "4px" }}
                            ></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {" "}
                            <Dropdown.Item
                              onSelect={this.handleProgramSelect}
                              eventKey="Software Program"
                              style={{ color: "white" }}
                              // key={job.jobTitle}
                            >
                              Software Program
                            </Dropdown.Item>
                            <Dropdown.Item
                              onSelect={this.handleProgramSelect}
                              eventKey="Graphic Design Program"
                              style={{ color: "white" }}
                              // key={job.jobTitle}
                            >
                              Graphic Design Program
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                              onSelect={this.handleSelect}
                              eventKey='3'
                              style={{ color: 'white' }}
                              // key={job.jobTitle}
                            >
                              3
                            </Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>
                        {this.state.programError ? (
                          <span
                            style={{ fontSize: "13px" }}
                            className="errorMsg"
                          >
                            {" "}
                            {this.state.programError}
                          </span>
                        ) : null}
                        {this.state.yearError ? (
                          <span
                            style={{ fontSize: "13px" }}
                            className="errorMsg"
                          >
                            {" "}
                            {this.state.yearError}
                          </span>
                        ) : null}
                        {this.state.gpaError ? (
                          <span
                            style={{ fontSize: "13px" }}
                            className="errorMsg"
                          >
                            {" "}
                            {this.state.gpaError}
                          </span>
                        ) : null}
                        <div className="contactForm mb-4">
                          <div className="upload-btn-wrapper">
                            <button
                              className="Formbtn"
                              variant="contained"
                              component="label"
                            >
                              <i
                                className="fas fa-upload"
                                style={{ paddingRight: "20px" }}
                              ></i>
                              Click Here To Upload Your CV*
                            </button>
                            <input
                              onChange={this.setFile}
                              type="file"
                              style={{ color: "white", border: "none" }}
                            />
                          </div>

                          {this.state.file !== null ? (
                            <p style={{ color: "white" }} className="p-1 m-1 ">
                              {" "}
                              {this.state.file.name}
                            </p>
                          ) : null}
                        </div>
                        <div className="errorMsg">{this.state.errors.cv}</div>
                        <Col sm={12}>
                          <Row className="contactfields">
                            <Col sm={9}>
                              <p className="required-note">
                                * fields marked with an asterisk (*) are
                                required to fullfil the form. failing to
                                complete any of those fields will result in a
                                failure to submit your request accompanied by a
                                failure message.
                              </p>
                            </Col>
                            <Col sm={3}>
                              {" "}
                              <Button
                                for="apply"
                                type="submit"
                                className="submitButton"
                              >
                                SUBMIT
                              </Button>
                            </Col>
                          </Row>
                          {this.state.FEError ? (
                            <span className="BbachError">
                              {" "}
                              {this.state.FEError}
                            </span>
                          ) : null}
                          {this.state.BEError ? (
                            <span className="BbachError">
                              {" "}
                              <br /> {this.state.BEError}
                            </span>
                          ) : null}
                        </Col>
                      </Container>
                    </form>
                  </Col>
                  <Col sm={12} md={3} className="mt-5 contactnote formnote">
                    <img
                      alt="dotsapproach"
                      src="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/rect.svg"
                      className="notepic"
                    />
                    <h6>note:</h6>
                    <hr className="applynote-hr" />
                    <p>
                      we will be sending
                      <br className="d-none d-xl-block" /> you an e-mail{" "}
                      <br className="d-none d-xl-block" /> shortly with all{" "}
                      <br className="d-none d-xl-block" /> the details we need{" "}
                      <br className="d-none d-xl-block" /> to start on your
                      <br className="d-none d-xl-block" /> next big project. if{" "}
                      <br className="d-none d-xl-block" /> you’re in a hurry,
                      <br className="d-none d-xl-block" /> you could always{" "}
                    </p>
                    <br />
                    {/* <p>
                      call us on <br className="d-none d-xl-block" />
                  +2 01003388470 or
                </p> */}
                    <p>
                      {" "}
                      e-mail us on
                      <br className="d-none d-xl-block" /> internship@lirten.com
                    </p>
                  </Col>
                </Row>{" "}
                <Modal className="mt-2" show={this.state.show}>
                  <div id="snackbar">Sent Successfully</div>
                </Modal>
              </Container>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Internform;
