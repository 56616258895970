import React, { Component } from 'react'
import '../../stylesheets/JoinUs.css'

export default class NoAvailable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.title,
    }
  }

  render() {
    return (
      <div className="NoAvailable">
        {this.state.title === 'hiring' ? (
          <h1>
            no available vacancies <br /> keep in touch for any updates
          </h1>
        ) : (
          <h1>
            no available programs <br /> keep in touch for any updates
          </h1>
        )}
        <img
          className="NoAvailableImage "
          src="https://lirten.s3.eu-central-1.amazonaws.com/website/LirtenNoAv.png"
          alt=" "
        />
      </div>
    )
  }
}
