import React, { Component } from "react";
import StartSection from "../sections/joinUs-sections/StartSection";
import "../stylesheets/Internship.css";
import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import { Link } from "react-router-dom";
import Interncard from "../sections/joinUs-sections/Interncard";
import InternForm from "../sections/joinUs-sections/Internform";
// import InternCarousel from '../sections/joinUs-sections/InternCarousel'
import NoAvailable from "../sections/joinUs-sections/NoAvailable";
import "../stylesheets/header.css";
// import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons";

const { internship } = require("../../data/internship");

export default class Internship extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "",
      fixNav: "py-5  fix-nav float-left  col-lg-3 fix",
    };
    this.headerend = React.createRef();
    this.thanknote = React.createRef();
    this.line = React.createRef();
    this.sticky = React.createRef();
    this.list = React.createRef();
    this.applybtn = React.createRef();
    this.first = React.createRef();
    this.second = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (internship.length !== 0) {
      if (this.props.match.path === "/internship") {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("scroll", this.fix);
      } else {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("scroll", this.fix);
      }
    }
  }

  componentWillUnmount() {
    if (internship.length !== 0) {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("scroll", this.fix);
    }
  }

  fix = (e) => {
    if (internship.length !== 0) {
      if (
        window.pageYOffset > this.headerend.current.offsetTop &&
        window.pageYOffset + 0.5 * window.innerHeight <
          this.thanknote.current.offsetTop
      ) {
        this.setState({ fixNav: "py-5  fix-nav float-left  col-lg-0 fix" });
        this.sticky.current.className = this.state.fixNav;
      } else if (
        window.pageYOffset + 0.5 * window.innerHeight >=
        this.thanknote.current.offsetTop
      ) {
        this.sticky.current.className = "disappear";
      } else {
        this.sticky.current.classList =
          " py-5  fix-nav float-left  col-lg-0 stick";
      }
    }
  };
  handlelist = (e) => {
    const list = this.list.current;
    if (internship.length !== 0) {
      window.scrollTo({
        top: list.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: list.offsetTop - 300,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  handleapply = (e) => {
    const applybtn = this.applybtn.current;
    window.scrollTo({
      top: applybtn.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };
  handleScroll = (e) => {
    if (
      window.pageYOffset >= this.list.current.offsetTop &&
      window.pageYOffset < this.applybtn.current.offsetTop
    ) {
      this.line.current.classList = "lineblue";
      this.first.current.classList = "blue";
      this.second.current.classList = "gray";
    } else if (window.pageYOffset >= this.applybtn.current.offsetTop) {
      this.line.current.classList = "linewhite";
      this.second.current.classList = "white";

      this.first.current.classList = "gray";
    }
  };
  render() {
    return (
      <div className="adjusting">
        <div className="intern">
          {" "}
          <div className="pt-5 mobileheader">
            <div className="logo">
              {" "}
              <Link to="/">
                <picture>
                  <source
                    srcSet="https://lirtenwebsite.s3.me-south-1.amazonaws.com/headerlogo.webp"
                    alt="lirtenHeader"
                    type="image/webp"
                  />
                  <source
                    srcSet="https://lirtenwebsitepng.s3.me-south-1.amazonaws.com/logo.png"
                    alt="lirtenHeader"
                    type="image/png"
                  />{" "}
                  <img src="img/creakyOldJPEG.jpg" alt="lirtenHeader" />
                </picture>{" "}
              </Link>
            </div>
            <Link className="homeLink px-2" to="/">
              {" "}
              Home
            </Link>
            <Dropdown className="headmenu">
              <DropdownToggle className="jointoggle joinLink px-2">
                {" "}
                JOIN OUR TEAM
              </DropdownToggle>
              <Dropdown.Menu className="headmenu2">
                <Dropdown.Item className="joinLink joinitem joinitem2">
                  {" "}
                  <Link to="/joinOurTeam" className="joinButton joinButton1">
                    hiring{" "}
                  </Link>{" "}
                </Dropdown.Item>
                <Dropdown.Item className="joinLink joinitem joinitem3 ">
                  {" "}
                  internshsip{" "}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* <div className='clearfix'></div> */}
          <div className="m-auto pt-3 text-center">
            {" "}
            <h1>INTERNSHIP PROGRAM</h1>
          </div>
          <Container className="title m-auto pt-4 text-center">
            <Row>
              <Col className="m-auto text-center" sm={12} md={7}>
                {" "}
                <p style={{ fontWeight: "600", fontSize: "2.4vw" }}>
                  SUMMER 2021
                </p>
              </Col>
            </Row>
            <Row className="buttonRow">
              <Col className="m-auto text-center" sm={12}>
                {" "}
                <Button
                  onClick={this.handlelist}
                  style={{ width: "150px" }}
                  className="joinbtn"
                >
                  APPLY NOW
                </Button>
              </Col>
            </Row>
          </Container>
          <div className="socials">
            {" "}
            <a
              href="https://www.linkedin.com/company/software-company-lirten-solutions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <i className="fab fa-linkedin pr-2"></i>
            </a>
            <a
              href="https://twitter.com/LirtenSolutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <i className="fab fa-twitter-square pr-2"></i>
            </a>
            <a
              href="https://www.facebook.com/LirtenSolutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <i className="fab fa-facebook-square pr-2"></i>
            </a>
            {/* here will be instagram link */}
            <a
              href="https://www.instagram.com/lirten_solutions/?igshid=MzNlNGNkZWQ4Mg%3D%3D"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <i
                className="fab fa-instagram "
                // style={{
                //   borderRadius: "5px",
                //   backgroundColor: "white",
                //   color: "black",
                //   maxWidth: "fit-content",
                //   maxHeight: "fit-content",
                // }}
              ></i>
            </a>
          </div>
        </div>
        <div className="offer py-5" ref={this.headerend}></div>
        {internship.length !== 0 ? (
          <div>
            <Container>
              <Row>
                <Col lg={4}>
                  <div className="d-none d-lg-block stickynavbtn">
                    <div
                      className="  py-5 float-left col-lg-4"
                      ref={this.sticky}
                      style={{ zIndex: "999" }}
                    >
                      <div>
                        <button
                          onClick={this.handlelist}
                          className="text-right"
                        >
                          {" "}
                          <p
                            ref={this.first}
                            className="blue text-right"
                            style={{ textTransform: "uppercase" }}
                          >
                            {" "}
                            <br />
                            Description
                          </p>{" "}
                        </button>
                        <br />
                        <button
                          onClick={this.handleapply}
                          className="text-right"
                        >
                          <p ref={this.second} className="gray">
                            {" "}
                            <br />
                            APPLY NOW
                          </p>
                        </button>
                        <span className="lineblue" ref={this.line}></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={8} ref={this.list}>
                  <h2 className="IPtext">internship program</h2>
                  <div className="thanks">
                    <img
                      style={{ width: "10%" }}
                      src="https://lirten.s3.eu-central-1.amazonaws.com/website/Rectangle_10.png"
                      alt=""
                    />
                  </div>
                  <div className="newpart">
                    Welcome to Lirten 2021 Internship program! The program helps
                    undergraduate students to explore the industry. People
                    willing to learn and explore will fit and gain the most out
                    of the program.
                  </div>
                  {internship.map((ell) => (
                    <Interncard handleapply={this.handleapply} list={ell} />
                  ))}
                </Col>
              </Row>
            </Container>
            <div ref={this.applybtn}>
              <InternForm />
            </div>
            <div ref={this.thanknote}>
              <StartSection />
            </div>
          </div>
        ) : (
          <div ref={this.list} style={{ marginTop: "1vw" }}>
            <NoAvailable title="internship" />
          </div>
        )}
      </div>
    );
  }
}
